var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled, { css } from 'styled-components';
import { BodyTemplate, TextComponent, useEntireLoader, CustomDropdownMenu, CustomerTextButton, FilterWithSearcher, EntireLoader, NoticeSentenceWithLink, useGlobalModal, BigTitleModal, AntPrimaryGhostButton, UnderlineClickableText, CustomDropdownContainButton, XScrollableTable, HookFormInputs, typographyByName, ScrollBarWithMeasure, MyEditor } from 'renderer/components';
import { StoreMessageModel } from 'renderer/models';
import { MessageTemplateQuery, StoreQuery } from 'renderer/queries';
import dayjs from 'dayjs';
import { checkEucKrCompatibility, createUUID, getByteLength, isEmpty, removeZeroWidthSpace, set, uploadFileToMessageImageDirectory } from 'renderer/utils';
import { COLORS } from 'renderer/styles';
import notification from 'antd/lib/notification';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import { Row } from 'antd/lib/grid';
import { Marginer } from 'renderer/components';
import { Layout } from 'common-ui';
import { Icon } from 'common-ui/Icon';
import { useForm } from 'react-hook-form';
import Checkbox from 'antd/lib/checkbox';
import { AD_PREFIX, AD_SUFFIX, ByteChecker, getAdText, MessageError, MessageImage, PictureSelector } from './MessageTemplates';
import { normalizeWhitespace } from 'common-utils/lodash';
var MessageHistory = function (props) {
    return (React.createElement(BodyTemplate, { title: "\uBB38\uC790 \uB0B4\uC5ED", content: React.createElement(ScrollBarWithMeasure, { additionalHeight: 80 },
            React.createElement(MessageHistoryContent, null)) }));
};
export var MessageHistoryContent = function (props) {
    var store = StoreQuery.getMyStoreInCache();
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var _a = MessageTemplateQuery.useMesageHistoryPager({
        storeId: storeId,
        limit: 10,
        page: 1,
        customerId: props.customerId,
        sortBy: 'createdAt',
        sortDir: 'desc'
    }, { enabled: !!storeId }), pagerResult = _a.pagerResult, isLoading = _a.isLoading, getPaginateObject = _a.getPaginateObject, refetch = _a.refetch, setNewPagerOptions = _a.setNewPagerOptions;
    var columns = [
        {
            title: '발송유형',
            dataIndex: 'trigger',
            key: 'trigger',
            width: 80,
            render: function (_, raw) { return raw.sendTypeString; }
        },
        {
            title: '문자유형',
            dataIndex: 'messageType',
            key: 'messageType',
            width: 80,
            render: function (_, raw) { return "".concat(raw.messageTypeString).concat(raw.isGroup ? ' (단체)' : ''); }
        },
        {
            title: '발송상태',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            render: function (_, raw) { return raw.statusString; }
        },
        {
            title: '발송(예약)일시',
            dataIndex: 'sendAt',
            key: 'sendAt',
            width: 120,
            sorter: true,
            render: function (_, row) {
                return dayjs(row.sendAt).format('YYYY-MM-DD (HH:mm:ss)');
            }
        },
        {
            title: '받는사람',
            dataIndex: 'receiveName',
            key: 'receiveName',
            width: 180,
            render: function (_, row) {
                var _a;
                return row.raw ? (React.createElement(CustomerTextButton, { type: "caption1", customer: { id: (_a = row.raw) === null || _a === void 0 ? void 0 : _a.customerId, name: "".concat(row.raw.receiverName, "(").concat(row.receiverToString, ")") } })) : ('');
            }
        },
        {
            title: '문자항목',
            dataIndex: ['trigger', 'triggerName'],
            key: 'content',
            width: 80
        },
        {
            title: '발송문구',
            dataIndex: ['raw', 'body'],
            key: 'body',
            width: 1050
        },
        {
            title: '등록일시',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 120,
            sorter: true,
            render: function (_) {
                return dayjs(_).format('YYYY-MM-DD (HH:mm:ss)');
            }
        }
    ];
    var _b = React.useState(), selected = _b[0], setSelected = _b[1];
    var handleClose = function () { return setSelected(undefined); };
    var handleTableChange = function (pagination, filters, sorter) {
        setNewPagerOptions({
            sortBy: (sorter === null || sorter === void 0 ? void 0 : sorter.columnKey) || 'createdAt',
            sortDir: (sorter === null || sorter === void 0 ? void 0 : sorter.order) || 'desc',
            page: pagination.current,
            limit: pagination.pageSize
        });
    };
    var _c = React.useState({ keyword: '' }), filterValue = _c[0], setFilterValue = _c[1];
    return storeId ? (React.createElement(Wrapper, null,
        React.createElement(StyledNoticeSentenceWithLink, { title: "\uBB38\uC790\uBC1C\uC1A1\uC5D0 \uC2E4\uD328\uD55C\uB2E4\uBA74 \uAF2D \uD655\uC778\uD574\uC8FC\uC138\uC694 >", url: "https://oxidized-duke-790.notion.site/425ed7705a454cc7abab72e4cf03f28d" }),
        React.createElement(FilterWithSearcher, { hideKeyword: !!props.customerId, filter: [
                {
                    key: 'status',
                    type: 'checkbox',
                    title: '발송상태',
                    items: [
                        { label: '접수', value: 'requested' },
                        { label: '예약', value: 'reserved' },
                        { label: '취소', value: 'cancelled' },
                        { label: '성공', value: 'completed' },
                        { label: '실패', value: 'failure' },
                        { label: '실패(수신거부)', value: 'failureByReject' },
                        { label: '취소(광고)', value: 'cancelledByAd' },
                        { label: '실패(광고)', value: 'failureByAd' },
                        { label: '실패(포인트부족)', value: 'insufficientPoint' },
                        { label: '대체(성공)', value: 'altCompleted' },
                        { label: '대체(실패)', value: 'altFailure' }
                    ]
                },
                { title: '발송 일시', key: 'sendAt', type: 'dateRange' }
            ], value: filterValue, onChange: function (value) {
                setFilterValue(value);
                setNewPagerOptions({
                    sendAt: value === null || value === void 0 ? void 0 : value.sendAt,
                    search: value === null || value === void 0 ? void 0 : value.keyword,
                    status: value === null || value === void 0 ? void 0 : value.status,
                    page: 1
                });
            } }),
        React.createElement(Marginer, { height: 10 }),
        React.createElement(XScrollableTable, { id: "messageTable", resizable: { additionalColumnWidth: 0 }, onChange: handleTableChange, loading: isLoading, columns: columns, bordered: false, dataSource: pagerResult.items, pagination: getPaginateObject(), onRow: function (data) { return ({
                onClick: function () { return setSelected(data); }
            }); } }),
        React.createElement(MessageDialog, { store: store, selected: selected, onClose: handleClose, onRefetch: function () {
                refetch();
            } }))) : (React.createElement(EntireLoader, { open: true }));
};
var Status = function (props) {
    var selected = props.selected;
    var color = COLORS.success;
    // Status = 'scheduled' | 'cancelled' | 'request' | 'requested' | 'completed' | 'failure' | 'rejected';
    if (selected.isReserved) {
        color = COLORS.warning;
    }
    if (['cancelled', 'failure', 'rejected'].includes(selected.status)) {
        color = COLORS.calendarRedActive;
    }
    return (React.createElement(TextComponent, { color: color, marginLeft: 6, type: "caption1" }, selected.statusString));
};
var MessageDialog = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var selected = props.selected;
    var handleClose = props.onClose;
    var cancel = MessageTemplateQuery.useCancelMessage();
    var loader = useEntireLoader();
    var handleCancelReserve = function (selected) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            Modal.confirm({
                cancelText: '아니오',
                centered: true,
                okText: '예',
                title: '메시지 전송 취소',
                zIndex: 99999,
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, 3, 4]);
                                loader.show();
                                return [4 /*yield*/, cancel.mutateAsync({
                                        requestId: selected.requestId,
                                        storeId: selected.storeId
                                    })];
                            case 1:
                                _a.sent();
                                setTimeout(function () {
                                    loader.hide();
                                    props.onRefetch();
                                    notification.success({ message: '예약문자 발송이 취소되었습니다.' });
                                }, 1000);
                                return [3 /*break*/, 4];
                            case 2:
                                error_1 = _a.sent();
                                loader.hide();
                                return [3 /*break*/, 4];
                            case 3:
                                props.onClose();
                                return [7 /*endfinally*/];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); },
                onCancel: function () {
                    props.onClose();
                },
                content: (React.createElement("div", null, selected.isGroup ? (React.createElement(TextComponent, { children: "\uC774 \uBA54\uC2DC\uC9C0\uB294 \uADF8\uB8F9\uBC1C\uC1A1\uB41C \uBA54\uC2DC\uC9C0\uC785\uB2C8\uB2E4. \uD568\uAED8 \uBC1C\uC1A1\uC608\uC57D\uB41C \uBAA8\uB4E0 \uBA54\uC2DC\uC9C0\uAC00 \uCDE8\uC18C\uB429\uB2C8\uB2E4. \uADF8\uB798\uB3C4 \uCDE8\uC18C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?" })) : (React.createElement(TextComponent, { children: "\uBC1C\uC1A1\uC608\uC57D\uB41C \uBA54\uC2DC\uC9C0 \uBC1C\uC1A1\uC744 \uCDE8\uC18C\uD569\uB2C8\uB2E4." }))))
            });
            return [2 /*return*/];
        });
    }); };
    var send = MessageTemplateQuery.useSendMessage();
    var _g = React.useState(), newMessage = _g[0], setNewMessage = _g[1];
    var customerModal = useGlobalModal();
    var methods = useForm();
    var sendAt = methods.watch('sendAt');
    var typeString = ['fts', 'fms'].includes(newMessage === null || newMessage === void 0 ? void 0 : newMessage.messageType)
        ? '친구톡'
        : (newMessage === null || newMessage === void 0 ? void 0 : newMessage.messageType) === 'ats'
            ? '알림톡'
            : '문자';
    var label = newMessage ? (!newMessage.id ? "".concat(typeString, "\uBC1C\uC1A1(\uC608\uC57D)") : "\uC608\uC57D".concat(typeString, " \uC218\uC815")) : '문자 상세';
    var disabledForPopbill = (selected === null || selected === void 0 ? void 0 : selected.messageType) === 'ats' && (selected === null || selected === void 0 ? void 0 : selected.provider) === 'popbill';
    return (React.createElement(BigTitleModal, { width: 360, centered: true, cancelText: "\uB2EB\uAE30", footer: newMessage ? (React.createElement(Footer, { gutter: 4, justify: "center" },
            React.createElement(AntPrimaryGhostButton, { children: "\uB2EB\uAE30", onClick: function () { return setNewMessage(null); } }),
            React.createElement(Button, { children: label, type: "primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var values, value, image, type, isAd, error, bodyLength, compatibility, customerId, uploadResult, body, payload, err_1;
                    var _a, _b, _c, _d, _e, _f;
                    return __generator(this, function (_g) {
                        switch (_g.label) {
                            case 0:
                                values = methods.getValues();
                                if (values.sendAt && dayjs(values.sendAt).isBefore(dayjs())) {
                                    return [2 /*return*/, notification.error({ message: '발송시간은 현재시간 이후로 설정되어야합니다.' })];
                                }
                                value = (_a = values.raw) === null || _a === void 0 ? void 0 : _a.body;
                                if (!!value) return [3 /*break*/, 1];
                                return [2 /*return*/, methods.setError('raw.body', { message: '본문을 입력해주세요.' })];
                            case 1:
                                image = values.raw.image;
                                type = values.messageType;
                                isAd = values.isAd;
                                value = value + (isAd ? getAdText(props.store) : '');
                                value = normalizeWhitespace(value);
                                error = void 0;
                                if (type === 'message' || !type) {
                                    if (getByteLength(value) > 2000) {
                                        error = '문자는 2000kb를 넘을 수 없습니다.';
                                    }
                                }
                                else {
                                    bodyLength = value.length;
                                    if (image) {
                                        if (bodyLength > 400) {
                                            error = '이미지 친구톡은 400자를 넘을 수 없습니다.';
                                        }
                                    }
                                    else {
                                        if (bodyLength > 1000) {
                                            error = '친구톡은 1000자를 넘을 수 없습니다.';
                                        }
                                    }
                                }
                                compatibility = checkEucKrCompatibility(value);
                                if (!compatibility.compatible) {
                                    error = "\uC774\uBAA8\uC9C0(ex. \uD83D\uDE01) \uB4F1 \uBB38\uC790\uC5D0\uC11C \uD5C8\uC6A9\uD558\uC9C0 \uC54A\uB294 \uBB38\uC790\uAC00 \uC788\uC2B5\uB2C8\uB2E4. (".concat(compatibility.incompatibleChars.join(', '), ")");
                                }
                                if (!!error) return [3 /*break*/, 9];
                                loader.show();
                                methods.clearErrors();
                                customerId = (_b = values === null || values === void 0 ? void 0 : values.raw) === null || _b === void 0 ? void 0 : _b.customerId;
                                if (!customerId) {
                                    return [2 /*return*/, notification.error({ message: '발송대상이 없습니다.' })];
                                }
                                _g.label = 2;
                            case 2:
                                _g.trys.push([2, 6, 7, 8]);
                                if (!(image && typeof image !== 'string' && image[0] && image[0].originFileObj)) return [3 /*break*/, 4];
                                return [4 /*yield*/, uploadFileToMessageImageDirectory(image[0].originFileObj, createUUID())];
                            case 3:
                                uploadResult = _g.sent();
                                image = uploadResult.location;
                                _g.label = 4;
                            case 4:
                                body = removeZeroWidthSpace((_c = values === null || values === void 0 ? void 0 : values.raw) === null || _c === void 0 ? void 0 : _c.body);
                                payload = {
                                    storeId: (_d = props.store) === null || _d === void 0 ? void 0 : _d.id,
                                    isAd: isAd,
                                    type: values.messageType,
                                    sendMessageOnFailAlimtalk: ((_e = values.raw) === null || _e === void 0 ? void 0 : _e.sendMessageOnFailAlimtalk) || false,
                                    sendAt: values.sendAt || undefined,
                                    content: image ? { body: body, image: image } : { body: body },
                                    target: {
                                        isAll: false,
                                        customerIds: [customerId]
                                    },
                                    templateCode: ((_f = values.raw) === null || _f === void 0 ? void 0 : _f.templateCode) || null,
                                    prevMessageId: values.id || null
                                };
                                return [4 /*yield*/, send.mutateAsync(payload)];
                            case 5:
                                _g.sent();
                                setNewMessage(null);
                                handleClose();
                                notification.success({ message: "".concat(label, " \uC644\uB8CC") });
                                return [3 /*break*/, 8];
                            case 6:
                                err_1 = _g.sent();
                                notification.success({ message: err_1.message || "".concat(label, " \uC911 \uC5D0\uB7EC \uBC1C\uC0DD") });
                                return [3 /*break*/, 8];
                            case 7:
                                loader.hide();
                                return [7 /*endfinally*/];
                            case 8: return [3 /*break*/, 10];
                            case 9:
                                methods.clearErrors();
                                methods.setError('raw.body', { message: error });
                                _g.label = 10;
                            case 10: return [2 /*return*/];
                        }
                    });
                }); } }))) : (React.createElement(Footer, { gutter: 4, justify: "center" },
            React.createElement(AntPrimaryGhostButton, { children: "\uB2EB\uAE30", onClick: handleClose }),
            (selected === null || selected === void 0 ? void 0 : selected.isGroup) ? ((selected === null || selected === void 0 ? void 0 : selected.isReserved) ? (React.createElement(Button, { children: "\uB2E8\uCCB4\uBB38\uC790 \uBC1C\uC1A1\uCDE8\uC18C", onClick: function () {
                    handleCancelReserve(selected);
                }, type: "primary" })) : (React.createElement(React.Fragment, null))) : (selected === null || selected === void 0 ? void 0 : selected.isReserved) ? (React.createElement(CustomDropdownContainButton, { items: [
                    {
                        name: 'mail',
                        label: "\uC0C8\uBB38\uC790 \uBC1C\uC1A1",
                        icon: 'send',
                        onClick: function () {
                            var _a, _b;
                            if (disabledForPopbill) {
                                return notification.info({
                                    message: '과거 시스템을 통해 보내진 알림톡은 새문자 발송을 이용할 수 없습니다. 관리자에게 문의해주세요.'
                                });
                            }
                            setNewMessage(__assign(__assign({}, selected), { id: null, raw: __assign(__assign({}, selected.raw), { body: (_b = (_a = selected.raw) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.replace(/무료거부:0808803428|\(광고\).+\n/g, '') }) }));
                        }
                    },
                    {
                        name: 'modify',
                        label: "\uBC1C\uC1A1 \uC218\uC815",
                        icon: 'edit3',
                        disabled: disabledForPopbill,
                        onClick: function () {
                            var _a, _b;
                            if (disabledForPopbill) {
                                return notification.info({
                                    message: '과거 시스템을 통해 보내진 알림톡은 발송수정을 이용할 수 없습니다. 관리자에게 문의해주세요.'
                                });
                            }
                            setNewMessage(__assign(__assign({}, selected), { raw: __assign(__assign({}, selected.raw), { body: (_b = (_a = selected.raw) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.replace(/무료거부:0808803428|\(광고\).+\n/g, '') }) }));
                        }
                    },
                    {
                        name: 'delete',
                        label: "\uBC1C\uC1A1 \uCDE8\uC18C",
                        icon: 'trash',
                        onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                handleCancelReserve(selected);
                                return [2 /*return*/];
                            });
                        }); },
                        color: COLORS.calendarRedActive
                    }
                ], buttonProps: { children: "\uBC1C\uC1A1\uC218\uC815\u2219\uCDE8\uC18C" } })) : (React.createElement(Button, { children: "\uC0C8\uBB38\uC790\uBC1C\uC1A1", type: "primary", onClick: function () {
                    var _a, _b;
                    if (disabledForPopbill) {
                        return notification.info({
                            message: '과거 시스템을 통해 보내진 알림톡은 새문자발송을 이용할 수 없습니다. 관리자에게 문의해주세요.'
                        });
                    }
                    setNewMessage(__assign(__assign({}, selected), { sendAt: null, id: null, raw: __assign(__assign({}, selected.raw), { body: (_b = (_a = selected.raw) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.replace(/무료거부:0808803428|\(광고\).+\n/g, '') }) }));
                } })))), onCancel: newMessage ? function () { return setNewMessage(null); } : handleClose, open: !!selected, title: newMessage ? label : '문자 상세', children: newMessage ? (React.createElement(DetailWrapper, null,
            React.createElement(Layout.FluidInline, null,
                React.createElement(GrayText, null,
                    React.createElement(TextComponent, { color: COLORS.gray4, type: "caption1" }, "".concat((_a = newMessage === null || newMessage === void 0 ? void 0 : newMessage.raw) === null || _a === void 0 ? void 0 : _a.receiverName, " (").concat(StoreMessageModel.receiverToString((_b = newMessage.raw) === null || _b === void 0 ? void 0 : _b.receiver), ")")))),
            React.createElement(Layout.FluidInline, { align: "center" },
                React.createElement(CustomDatepicker, { controlProps: {
                        name: 'sendAt',
                        control: methods.control,
                        rules: {
                            validate: function (value) {
                                if (dayjs(value).isBefore(dayjs())) {
                                    return '발송시간은 현재시간 이후로 설정되어야합니다.';
                                }
                            }
                        }
                    }, inputProps: { disabled: !sendAt, disabledDate: function (date) { return dayjs(date).isBefore(dayjs()); } } }),
                React.createElement(Checkbox, { children: "\uD604\uC7AC\uC2DC\uAC04", checked: !sendAt, onClick: function () {
                        methods.setValue('sendAt', !sendAt ? dayjs() : null);
                    } })),
            React.createElement(Layout.FluidInline, null, newMessage && (React.createElement(MessageEditInput, { store: props.store, message: newMessage, methods: methods }))))) : (React.createElement(DetailWrapper, null,
            React.createElement(UnderlineClickableText, { type: "headline3", onClick: function () {
                    var _a;
                    customerModal.open({
                        type: 'customerDetail',
                        data: {
                            customerId: Number((_a = selected === null || selected === void 0 ? void 0 : selected.raw) === null || _a === void 0 ? void 0 : _a.customerId)
                        }
                    });
                }, children: "".concat((_c = selected === null || selected === void 0 ? void 0 : selected.raw) === null || _c === void 0 ? void 0 : _c.receiverName, "(").concat(selected === null || selected === void 0 ? void 0 : selected.receiverToString, ") > ") }),
            React.createElement(Layout.FluidInline, { align: "center", gutter: 5 },
                React.createElement(Icon, { name: "clock", color: COLORS.gray4 }),
                React.createElement(TextComponent, { type: "caption1", marginTop: 10, marginBottom: 6, marginLeft: 8, color: COLORS.gray1, children: "\uBC1C\uC1A1(\uC608\uC57D)\uC77C\uC2DC: ".concat(dayjs(selected === null || selected === void 0 ? void 0 : selected.sendAt).format('YYYY-MM-DD (HH:mm:ss)')) })),
            React.createElement(Layout.FluidInline, { align: "center", gutter: 5 },
                React.createElement(Icon, { name: "mail", color: COLORS.gray4 }),
                React.createElement(TextComponent, { type: "caption1", marginTop: 10, marginBottom: 6, marginLeft: 8, color: COLORS.gray1, children: "".concat(selected === null || selected === void 0 ? void 0 : selected.messageTypeString).concat((selected === null || selected === void 0 ? void 0 : selected.isGroup) ? '(단체)' : '') }),
                React.createElement(Status, { selected: selected })),
            React.createElement(DetailContentWrapper, { children: React.createElement(React.Fragment, null,
                    React.createElement(TextComponent, { children: (_d = selected === null || selected === void 0 ? void 0 : selected.raw) === null || _d === void 0 ? void 0 : _d.body, type: "caption1", color: COLORS.gray2 }),
                    ((_e = selected === null || selected === void 0 ? void 0 : selected.raw) === null || _e === void 0 ? void 0 : _e.image) && React.createElement(Images, { src: (_f = selected.raw) === null || _f === void 0 ? void 0 : _f.image })) }))) }));
};
var GrayText = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 2px;\n  background-color: ", ";\n  min-height: 36px;\n  display: flex;\n  align-items: center;\n  padding-left: 12px;\n  width: 100%;\n  padding: 10px;\n"], ["\n  border-radius: 2px;\n  background-color: ", ";\n  min-height: 36px;\n  display: flex;\n  align-items: center;\n  padding-left: 12px;\n  width: 100%;\n  padding: 10px;\n"])), COLORS.gray7);
var CustomDatepicker = styled(HookFormInputs.DateSelector)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  & > div {\n    width: 220px !important;\n  }\n  & .ant-picker-suffix {\n    display: none;\n  }\n  & input {\n    font-size: 12px !important;\n    border-radius: 2px !important;\n    background-color: ", " !important;\n    height: 36px !important;\n    padding-left: 12px !important;\n    font-weight: 400 !important;\n    ", "} \n  }\n  & .ant-picker {\n    width: 80px;\n    &:first-child {\n      width: 130px;\n      & input {\n        margin-right: 5px;\n      }\n    }\n  }\n"], ["\n  & > div {\n    width: 220px !important;\n  }\n  & .ant-picker-suffix {\n    display: none;\n  }\n  & input {\n    font-size: 12px !important;\n    border-radius: 2px !important;\n    background-color: ", " !important;\n    height: 36px !important;\n    padding-left: 12px !important;\n    font-weight: 400 !important;\n    ", "} \n  }\n  & .ant-picker {\n    width: 80px;\n    &:first-child {\n      width: 130px;\n      & input {\n        margin-right: 5px;\n      }\n    }\n  }\n"])), COLORS.gray7, css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      ", ";\n    "], ["\n      ", ";\n    "])), typographyByName('caption1')));
var MessageEditInput = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    React.useEffect(function () {
        props.methods.reset(props.message);
    }, [props.message]);
    var isATS = props.message.messageType === 'ats';
    var ref = React.useRef();
    var handleReset = function () {
        props.methods.reset(props.message);
    };
    var values = props.methods.watch();
    var body = props.methods.watch('raw.body');
    var image = props.methods.watch('raw.image');
    var isAd = props.methods.watch('isAd');
    var error = (_d = (_c = (_b = (_a = props.methods.formState) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.raw) === null || _c === void 0 ? void 0 : _c.body) === null || _d === void 0 ? void 0 : _d.message;
    var messageType = values.messageType;
    var type = ['fts', 'fms'].includes(messageType) ? messageType : 'message';
    return isATS ? (React.createElement(DetailContentWrapper, null,
        React.createElement(TextComponent, { color: COLORS.gray4, type: "caption1" }, (_e = props.message.raw) === null || _e === void 0 ? void 0 : _e.body))) : (React.createElement("div", { style: { width: '100%' } },
        React.createElement(MessageTemplateContentWrapper, { error: !!error },
            React.createElement(Row, { justify: "space-between", className: "header", align: "middle" },
                React.createElement(ByteChecker, { body: body, isAd: isAd, store: props.store, hasImage: !!image, type: type })),
            React.createElement("div", { style: {
                    maxHeight: '270px',
                    overflowY: 'hidden',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px'
                } },
                image && (React.createElement(MessageImage, { image: image, onClick: function () {
                        props.methods.setValue('raw.image', null);
                    } })),
                isAd && React.createElement(AdPrefixer, { children: AD_PREFIX(((_f = props.store) === null || _f === void 0 ? void 0 : _f.name) || '') }),
                React.createElement(MyEditor, { ref: ref, height: isAd && image ? '100px' : image ? '160px' : isAd ? '180px' : '230px', initialValue: ((_h = (_g = props.message) === null || _g === void 0 ? void 0 : _g.raw) === null || _h === void 0 ? void 0 : _h.body) || '', onChange: function (content) {
                        props.methods.setValue('raw.body', content);
                    }, placeholder: "\uBCF4\uB0B4\uC2E4 \uB0B4\uC6A9\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694. 90\uBC14\uC774\uD2B8 \uCD08\uACFC\uC2DC LMS\uB85C \uC790\uB3D9\uC804\uD658, \uC0AC\uC9C4\uCD94\uAC00 \uC2DC MMS\uB85C \uC790\uB3D9\uC804\uD658\uB429\uB2C8\uB2E4." }),
                isAd && React.createElement(AdPrefixer, { children: AD_SUFFIX((_j = props.store) === null || _j === void 0 ? void 0 : _j.id) })),
            React.createElement("div", { className: "footer" },
                React.createElement(UnderlineClickableText, { color: COLORS.calendarRedActive, onClick: function () {
                        props.methods.setValue('isAd', !isAd);
                    } },
                    "(\uAD11\uACE0)+080\uC218\uC2E0\uAC70\uBD80 ",
                    isAd ? '제거' : '삽입'),
                React.createElement(Row, { style: { marginTop: '10px' } },
                    React.createElement(PictureSelector, { file: image, onChange: function (data) {
                            props.methods.setValue('raw.image', isEmpty(data) ? undefined : data);
                        } })))),
        error && React.createElement(MessageError, { error: error })));
};
var AdPrefixer = styled(TextComponent)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-top: 5px;\n  padding-bottom: 5px;\n  color: ", ";\n"], ["\n  padding-top: 5px;\n  padding-bottom: 5px;\n  color: ", ";\n"])), COLORS.calendarRedActive);
var MessageTemplateContentWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border: solid 1px ", ";\n  background-color: var(--white);\n  height: 400px;\n  border-radius: 4px;\n  display: flex;\n  flex-direction: column;\n\n  & > .header {\n    border-bottom: solid 1px var(--gray-6);\n    padding: 14px;\n  }\n  & > .footer {\n    border-top: solid 1px var(--gray-6);\n    padding: 14px;\n  }\n  & .ProseMirror {\n    height: 100%;\n  }\n"], ["\n  border: solid 1px ", ";\n  background-color: var(--white);\n  height: 400px;\n  border-radius: 4px;\n  display: flex;\n  flex-direction: column;\n\n  & > .header {\n    border-bottom: solid 1px var(--gray-6);\n    padding: 14px;\n  }\n  & > .footer {\n    border-top: solid 1px var(--gray-6);\n    padding: 14px;\n  }\n  & .ProseMirror {\n    height: 100%;\n  }\n"])), function (props) { return (props.error ? COLORS.calendarRedActive : COLORS.gray6); });
var DetailWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 20px;\n  & > * {\n    margin-bottom: 10px;\n  }\n"], ["\n  padding: 20px;\n  & > * {\n    margin-bottom: 10px;\n  }\n"])));
var Wrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: 0px;\n  padding: 14px;\n  height: 100%;\n  width: 100%;\n"], ["\n  border: 0px;\n  padding: 14px;\n  height: 100%;\n  width: 100%;\n"])));
var Images = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: 10px;\n"], ["\n  width: 100%;\n  margin-top: 10px;\n"])));
var Footer = styled(Layout.FluidInline)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  & > button {\n    flex: 1;\n  }\n"], ["\n  & > button {\n    flex: 1;\n  }\n"])));
var DetailContentWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 10px 8px 12px 18px;\n  border-radius: 2px;\n  background-color: var(--gray-7);\n  white-space: pre-line;\n  width: 100%;\n"], ["\n  padding: 10px 8px 12px 18px;\n  border-radius: 2px;\n  background-color: var(--gray-7);\n  white-space: pre-line;\n  width: 100%;\n"])));
export default MessageHistory;
export var DropdownFilters = function (props) {
    var filters = props.filters;
    var values = props.values;
    var renderOverlay = function (filter) {
        switch (filter.type) {
            case 'menu':
                return (React.createElement(CustomDropdownMenu, { items: filter.items, onClick: function (data) {
                        var newValues = set(values, filter.key, data);
                        props.onChange(newValues);
                    } }));
        }
    };
    return (React.createElement(Row, null, filters.map(function (filter) {
        return React.createElement(Dropdown, { key: filter.key, overlay: function () { return renderOverlay(filter); }, children: React.createElement(StyledButton, null) });
    })));
};
var StyledNoticeSentenceWithLink = styled(NoticeSentenceWithLink)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-bottom: 10px;\n"], ["\n  margin-bottom: 10px;\n"])));
var StyledButton = styled(Button)(templateObject_12 || (templateObject_12 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
