var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation, useQuery } from 'react-query';
import { orderBy, RestClient } from 'renderer/utils';
import { StoreProductModel, StoreProductGroupModel } from 'renderer/models';
import { QUERY_CACHE } from 'renderer/queries';
var getProductsByStoreId = function (options) {
    var storeId = options.queryKey[1];
    var paranoid = options.queryKey[2];
    return RestClient.getInstance()
        .get("/stores/".concat(storeId, "/products?paranoid=").concat(paranoid ? 'true' : 'false'))
        .then(function (result) {
        return orderBy(result.data || [], ['type', 'priority'], ['desc', 'asc']).reduce(function (prev, current) {
            var store = current.store, groupsWithoutProducts = __rest(current, ["store"]);
            var products = orderBy(current.products, ['priority'], ['asc']);
            var group = new StoreProductGroupModel(__assign(__assign({}, current), { products: products }));
            products.map(function (product) {
                prev.products.push(new StoreProductModel(__assign(__assign({}, product), { productGroup: groupsWithoutProducts })));
            });
            prev.groups.push(group);
            return prev;
        }, { groups: [], products: [] });
    });
};
var createProductGroup = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/products/groups"), payload)
        .then(function (result) { return result.data; });
};
var updateProductGroups = function (data) {
    return RestClient.getInstance()
        .put("/stores/".concat(data.storeId, "/products/groups"), data.payload)
        .then(function (result) { return result.data; });
};
var deleteProductGroup = function (data) {
    return RestClient.getInstance()
        .remove("/stores/".concat(data.storeId, "/products/groups/").concat(data.id))
        .then(function (result) { return result.data; });
};
var createProduct = function (payload) {
    return RestClient.getInstance()
        .post("/stores/".concat(payload.storeId, "/products"), payload)
        .then(function (result) { return result.data; });
};
var updateProduct = function (data) {
    return RestClient.getInstance()
        .put("/stores/".concat(data.storeId, "/products"), data.payload)
        .then(function (result) { return result.data; });
};
var deleteProduct = function (data) {
    return RestClient.getInstance()
        .remove("/stores/".concat(data.storeId, "/products/").concat(data.id))
        .then(function (result) { return result.data; });
};
var mutationConfig = {
    onSuccess: function () {
        // TODO: 필터에 맞춰서 optimise
        QUERY_CACHE.invalidateQueries(['productGroups']);
        QUERY_CACHE.invalidateQueries(['products']);
        QUERY_CACHE.invalidateQueries(['ticketTemplates']);
    }
};
var filterActiveProducts = function (data) {
    return {
        products: data.products.filter(function (model) { return !model.isDeleted; }),
        groups: data.groups
            .filter(function (model) { return !model.isDeleted; })
            .map(function (group) { return (__assign(__assign({}, group), { products: group.products.filter(function (product) { return !product.isDeleted; }) })); }),
    };
};
export var useProducts = function (_a, config) {
    var storeId = _a.storeId, _b = _a.paranoid, paranoid = _b === void 0 ? undefined : _b;
    var queryKey = ['products', storeId];
    if (paranoid === undefined) {
        var query = useQuery(__spreadArray(__spreadArray([], queryKey, true), [false], false), getProductsByStoreId, __assign({ staleTime: 60000 }, config));
        if (query.isFetched) {
            QUERY_CACHE.setQueryData(__spreadArray(__spreadArray([], queryKey, true), [true], false), filterActiveProducts(query.data));
        }
        return query;
    }
    return useQuery(__spreadArray(__spreadArray([], queryKey, true), [paranoid], false), getProductsByStoreId, __assign({ staleTime: 60000 }, config));
};
export var useCreateProductGroup = function (config) {
    return useMutation(createProductGroup, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateProductGroups = function (config) {
    return useMutation(updateProductGroups, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteProductGroup = function (config) {
    return useMutation(deleteProductGroup, __assign(__assign({}, mutationConfig), config));
};
export var useCreateProduct = function (config) {
    return useMutation(createProduct, __assign(__assign({}, mutationConfig), config));
};
export var useUpdateProducts = function (config) {
    return useMutation(updateProduct, __assign(__assign({}, mutationConfig), config));
};
export var useDeleteProduct = function (config) {
    return useMutation(deleteProduct, __assign(__assign({}, mutationConfig), config));
};
export var getProductByIdFromCache = function (storeId, productId, paranoid) {
    var _a, _b;
    if (paranoid === void 0) { paranoid = true; }
    return (_b = (_a = getProductsByStoreIdFromCache(storeId, paranoid)) === null || _a === void 0 ? void 0 : _a.products) === null || _b === void 0 ? void 0 : _b.find(function (item) { return (item === null || item === void 0 ? void 0 : item.id) === productId; }, paranoid);
};
export var getProductsByStoreIdFromCache = function (storeId, paranoid) {
    if (paranoid === void 0) { paranoid = true; }
    var queryKey = ['products', storeId];
    var data = QUERY_CACHE.getQueryData(__spreadArray(__spreadArray([], queryKey, true), [paranoid], false));
    if (!data) {
        var falseData = QUERY_CACHE.getQueryData(__spreadArray(__spreadArray([], queryKey, true), [false], false));
        var newData = filterActiveProducts(falseData);
        QUERY_CACHE.setQueryData(__spreadArray(__spreadArray([], queryKey, true), [true], false), newData);
        return newData;
    }
    return data;
};
export var useGetOnlyDemandProducts = function (_a, config) {
    var storeId = _a.storeId, paranoid = _a.paranoid;
    return useProducts({ storeId: storeId, paranoid: paranoid }, __assign(__assign({}, config), { select: function (data) {
            var _a;
            data.groups;
            return {
                products: (_a = data === null || data === void 0 ? void 0 : data.products) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.demandType === 'inside'; }),
                groups: data.groups
            };
        } }));
};
export var useGetOnlyProducts = function (_a, config) {
    var storeId = _a.storeId, paranoid = _a.paranoid;
    return useProducts({ storeId: storeId, paranoid: paranoid }, __assign(__assign({}, config), { select: function (data) {
            var _a;
            data.groups;
            return {
                products: (_a = data === null || data === void 0 ? void 0 : data.products) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item.productGroup.type === 'product'; }),
                groups: data.groups
            };
        } }));
};
