var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import React from 'react';
import { CustomDropdownButton, CustomerSearchInput, ListWithBadge, SALES_CONTROLLER_KEYS, checkPermissionForOnClick } from 'renderer/components';
import { TICKET_TYPE, SALES_TYPES, TICKET_USED_TYPE, ScheduleModel } from 'renderer/models';
import { PaymentHistoryQuery, StorePictureQuery, StoreQuery } from 'renderer/queries';
import { COLORS } from 'renderer/styles';
import { isEmpty } from 'renderer/utils';
import { MobileSalesFormResult, PCSalesFormLeft, PCSalesFormRight, PCSalesFormRow, SalesFormContainer, SalesModalFooter } from './SalesForm';
import { SalesAdditional } from './AdditionalFeatures';
import { IMAGES } from 'renderer/assets';
import { RefundForm } from './RefundForm';
import { SalesDetailContent } from './SalesDetailContents';
import { useGetScheduleModals, useScheduleModal } from 'renderer/stores';
export var SalesRemoteController = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    var isPC = props.isPC;
    var deletePaymentHistory = PaymentHistoryQuery.useDeletePaymentHistory();
    var deleteRefund = PaymentHistoryQuery.useCancelRefund();
    var store = StoreQuery.getMyStoreInCache();
    var scheduleModal = useScheduleModal();
    var scheduleModals = useGetScheduleModals();
    var allowUpdatePerm = props.salesPermissions.update;
    var handleClick = function (key) {
        if (checkPermissionForOnClick(allowUpdatePerm, '매출 수정 권한이 없습니다.')) {
            props.onChangeControlerKey(key);
        }
    };
    var sales = props.data.data;
    var salesPictures = StorePictureQuery.useCustomerPictures(store.id, { salesHistoryId: sales === null || sales === void 0 ? void 0 : sales.id, customerId: sales === null || sales === void 0 ? void 0 : sales.customerId }, {
        enabled: !!(store.id && (sales === null || sales === void 0 ? void 0 : sales.id))
    });
    var getBadge = function (data) {
        var text;
        text = (data === null || data === void 0 ? void 0 : data.signature) ? '완료' : null;
        return text;
    };
    var items = [
        !props.isPC && { value: SALES_CONTROLLER_KEYS.DETAILS, label: '결제내역서', onClick: handleClick },
        {
            value: SALES_CONTROLLER_KEYS.PICUTRES,
            label: '시술사진 등록',
            onClick: handleClick
        },
        (((_c = (_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.methods) === null || _c === void 0 ? void 0 : _c.prepaidPoint) || ((_f = (_e = (_d = props.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.methods) === null || _f === void 0 ? void 0 : _f.sharedPrepaidPoint)) && {
            value: SALES_CONTROLLER_KEYS.PREPAID,
            label: '선불액차감 문자발송',
            onClick: handleClick
        },
        !!((_j = (_h = (_g = props.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.ticketHistory) === null || _j === void 0 ? void 0 : _j.find(function (history) { var _a; return (history === null || history === void 0 ? void 0 : history.type) === TICKET_USED_TYPE.USED && ((_a = history === null || history === void 0 ? void 0 : history.ticket) === null || _a === void 0 ? void 0 : _a.type) === TICKET_TYPE.COUNT; })) && {
            value: SALES_CONTROLLER_KEYS.TICKETS,
            label: '횟수권차감 문자발송',
            onClick: handleClick
        },
        {
            value: SALES_CONTROLLER_KEYS.SIGNATURE,
            label: '서명',
            onClick: handleClick
        },
        ((_m = (_l = (_k = props.data) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.methods) === null || _m === void 0 ? void 0 : _m.unpaid) && {
            value: SALES_CONTROLLER_KEYS.UNPAID,
            label: '미수금 정산',
            onClick: handleClick
        },
        !((_p = (_o = props.data) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.isUsingHistory) &&
            ((_q = store === null || store === void 0 ? void 0 : store.preference) === null || _q === void 0 ? void 0 : _q.ticketQRActive) && {
            value: SALES_CONTROLLER_KEYS.SEND_QR,
            label: 'QR 코드 전송',
            onClick: handleClick
        }
    ]
        .filter(Boolean)
        .map(function (item) {
        var _a, _b;
        return (__assign(__assign({}, item), { badge: item.value === SALES_CONTROLLER_KEYS.PICUTRES ? ((_a = salesPictures.data) === null || _a === void 0 ? void 0 : _a.length) || 0 : getBadge((_b = props.data) === null || _b === void 0 ? void 0 : _b.data) }));
    });
    var handleDelete = function (record) { return __awaiter(void 0, void 0, void 0, function () {
        var content, modal;
        return __generator(this, function (_a) {
            if (!(record === null || record === void 0 ? void 0 : record.storeId)) {
                return [2 /*return*/];
            }
            if (!checkPermissionForOnClick(allowUpdatePerm, '매출 삭제 권한이 없습니다.')) {
                return [2 /*return*/];
            }
            content = !isEmpty(record.tickets)
                ? '매출 삭제 시 발행된 정기권도 함께 삭제됩니다.'
                : '매출을 삭제하시면 이용내역이 함께 삭제됩니다.';
            modal = Modal.confirm({
                title: '매출 삭제',
                centered: true,
                cancelText: '취소',
                autoFocusButton: null,
                okText: '삭제',
                cancelButtonProps: { children: '취소' },
                content: content,
                zIndex: 9999,
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var id, error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                id = record.id;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, deletePaymentHistory.mutateAsync({ id: id, storeId: record.storeId })];
                            case 2:
                                _a.sent();
                                notification.success({ message: '매출삭제가 완료되었습니다.' });
                                props.onClose();
                                // 매출 삭제 시 스케쥴 드로우가 켜져있다면 이를 갱신해주는 코드
                                scheduleModals.map(function (item) {
                                    var _a;
                                    if (Number((_a = item.schedule) === null || _a === void 0 ? void 0 : _a.salesHistoryId) === Number(id)) {
                                        scheduleModal.replace(__assign(__assign({}, item), { schedule: new ScheduleModel(__assign(__assign({}, item.schedule), { salesHistoryId: null })) }));
                                    }
                                });
                                return [3 /*break*/, 4];
                            case 3:
                                error_1 = _a.sent();
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); },
                onCancel: function () { return modal.destroy(); }
            });
            return [2 /*return*/];
        });
    }); };
    var type = (_s = (_r = props.data) === null || _r === void 0 ? void 0 : _r.data) === null || _s === void 0 ? void 0 : _s.type;
    var refundable = [SALES_TYPES.PRODUCT, SALES_TYPES.TICKET].includes(type);
    var hadnleRefundDelete = function (record) {
        if (!checkPermissionForOnClick(allowUpdatePerm, '매출 삭제 권한이 없습니다.')) {
            return;
        }
        var modal = Modal.confirm({
            title: '환불 내역 삭제',
            centered: true,
            cancelText: '취소',
            autoFocusButton: null,
            okText: '삭제',
            cancelButtonProps: { children: '취소' },
            content: '환불내역을 삭제하시면 복구 할 수 없습니다.',
            zIndex: 9999,
            onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                var id, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            id = record.id;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, deleteRefund.mutateAsync({ id: id, storeId: record.storeId })];
                        case 2:
                            _a.sent();
                            notification.success({ message: '환불삭제가 완료되었습니다.' });
                            props.onClose();
                            return [3 /*break*/, 4];
                        case 3:
                            error_2 = _a.sent();
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); },
            onCancel: function () { return modal.destroy(); }
        });
    };
    var renderFooter = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return (React.createElement(SalesModalFooter, { left: {
                element: (React.createElement(CustomDropdownButton, { buttonProps: { children: type === SALES_TYPES.REFUND ? '환불 취소' : '매출수정∙취소' }, items: [
                        refundable && {
                            name: 'refund',
                            label: '매출 환불',
                            icon: React.createElement("img", { src: IMAGES.icon_refund }),
                            onClick: function () {
                                handleClick(SALES_CONTROLLER_KEYS.REFUND);
                            },
                            disabled: !!((_c = (_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.refundId)
                        },
                        (refundable || type === SALES_TYPES.RESERVATION_FEE) && {
                            name: 'modify',
                            label: '매출 수정',
                            icon: 'edit3',
                            onClick: function () {
                                handleClick(SALES_CONTROLLER_KEYS.UPDATE_SALES);
                            },
                            disabled: !!((_f = (_e = (_d = props.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.refundId)
                        },
                        {
                            name: 'delete',
                            label: type === SALES_TYPES.REFUND ? '환불 삭제' : '매출 삭제',
                            icon: 'trash',
                            onClick: function () {
                                var _a, _b;
                                if (type === SALES_TYPES.REFUND) {
                                    hadnleRefundDelete((_a = props.data) === null || _a === void 0 ? void 0 : _a.data);
                                }
                                else {
                                    handleDelete((_b = props.data) === null || _b === void 0 ? void 0 : _b.data);
                                }
                            },
                            disabled: !!((_j = (_h = (_g = props.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.refundId),
                            color: COLORS.calendarRedActive
                        }
                    ].filter(Boolean) }))
            }, right: {
                buttonProps: {
                    children: '닫기',
                    type: 'primary',
                    onClick: props.onClose
                }
            } }));
    };
    var isSlectMenuMode = props.controllerKey === SALES_CONTROLLER_KEYS.SELECT_MENU;
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            if (!values) {
                return [2 /*return*/];
            }
            props.onSubmit(__assign(__assign(__assign({}, (_a = props.data) === null || _a === void 0 ? void 0 : _a.data), values), { changed: values.changed || props.controllerKey === SALES_CONTROLLER_KEYS.UNPAID ? { methods: true } : { meta: true } }));
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(React.Fragment, null, props.controllerKey === SALES_CONTROLLER_KEYS.UPDATE_SALES ? (React.createElement(SalesFormContainer, { data: props.data, onClickPurchaseTicket: props.onClickPurchaseTicket, onComplete: function (sales) {
            props.onComplete(sales);
            handleClick(SALES_CONTROLLER_KEYS.SELECT_MENU);
        }, onSubmit: props.onSubmit, onCancel: function () { return handleClick(SALES_CONTROLLER_KEYS.SELECT_MENU); } })) : props.controllerKey === SALES_CONTROLLER_KEYS.REFUND ? (React.createElement(RefundForm, { store: store, data: props.data, onComplete: function (sales) {
            props.onComplete(sales);
            handleClick(SALES_CONTROLLER_KEYS.SELECT_MENU);
        }, onChangeControlerKey: props.onChangeControlerKey })) : isPC ? (React.createElement(PCSalesFormRow, { wrap: false },
        React.createElement(PCSalesFormLeft, null,
            React.createElement(SalesDetailContent, { salesPermissions: props.salesPermissions, data: props.data, store: store, onChange: allowUpdatePerm ? function (memo) { return handleSubmit({ memo: memo }); } : null })),
        React.createElement(PCSalesFormRight, null,
            React.createElement(SearchInputForSalesDetail, { customerId: (_u = (_t = props.data) === null || _t === void 0 ? void 0 : _t.data) === null || _u === void 0 ? void 0 : _u.customerId, isDeleted: !((_w = (_v = props.data) === null || _v === void 0 ? void 0 : _v.data) === null || _w === void 0 ? void 0 : _w.customer) }),
            React.createElement("div", { className: "payment" }, isSlectMenuMode ? (React.createElement(ListWithBadge, { items: items })) : (React.createElement(SalesAdditional, { store: store, images: salesPictures.data, salesPermissions: props.salesPermissions, onSubmit: function (values) { return handleSubmit(values); }, data: props.data, controllerKey: props.controllerKey, isPC: isPC, onClickGoBack: function () { return handleClick(SALES_CONTROLLER_KEYS.SELECT_MENU); }, disabledUpdate: !allowUpdatePerm }))),
            isSlectMenuMode ? renderFooter() : React.createElement("div", null)))) : (React.createElement(MobileSalesFormResult, null,
        isSlectMenuMode && (React.createElement(SearchInputForSalesDetail, { customerId: (_y = (_x = props.data) === null || _x === void 0 ? void 0 : _x.data) === null || _y === void 0 ? void 0 : _y.customerId, isDeleted: !((_0 = (_z = props.data) === null || _z === void 0 ? void 0 : _z.data) === null || _0 === void 0 ? void 0 : _0.customer) })),
        React.createElement("div", { className: "payment" }, isSlectMenuMode ? (React.createElement(ListWithBadge, { items: items })) : (React.createElement(SalesAdditional, { store: store, images: salesPictures.data, onSubmit: function (values) { return handleSubmit(values); }, data: props.data, controllerKey: props.controllerKey, isPC: isPC, onClickGoBack: function () { return handleClick(SALES_CONTROLLER_KEYS.SELECT_MENU); }, disabledUpdate: !allowUpdatePerm, salesPermissions: props.salesPermissions }))),
        isSlectMenuMode ? renderFooter() : React.createElement("div", null)))));
};
export var SearchInputForSalesDetail = function (props) {
    return (React.createElement("div", { className: "customerSearcher" },
        React.createElement(CustomerSearchInput, { disabled: true, value: props.customerId, isDeletedCustomer: props.isDeleted })));
};
