var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CommonImages, Icon, Layout, Typhography } from 'common-ui';
import { HookFormInputs, TextComponent, useEntireLoader, KRWFomatToString, ColorTag, CreditCardRegister, EntireLoader, GuideIconOnlyLink, EntireBigTitleModal, ScrollBarWithMeasure, AntButton } from 'renderer/components';
import styled from 'styled-components';
import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import { MANAGER_PERMISSION_ACTIONS, MANAGER_PERMISSION_KEYS, MANAGER_PERMISSION_PRIORITY, OurProductModel, StoreSubscriptionModel } from 'renderer/models';
import { ManagerQuery, StorePaymentMethodQuery, StoreQuery } from 'renderer/queries';
import { EventTracker, floor, isEmpty, openWindow, useWindowSize } from 'renderer/utils';
import { DATE_FORMAT } from 'renderer/constants';
import { checkBizNoAndUpdate } from './Subscription';
import Form from 'antd/lib/form';
import notification from 'antd/lib/notification';
import { useMeasure } from 'react-use';
import Modal from 'antd/lib/modal';
import { COLORS } from 'renderer/styles';
import { Card, Col, Row } from 'antd/lib';
export var NewStoreSubscription = function (props) {
    var _a, _b, _c, _d, _e;
    var storeQuery = StoreQuery.useStore();
    var store = storeQuery.data;
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var currentPlan = store.subscription;
    var currentSubplans = store.subplanSubscriptions;
    var loader = useEntireLoader();
    var productsQuery = StorePaymentMethodQuery.useOurProducts(storeId, { enabled: !!storeId });
    var planProduct = (_a = productsQuery.data) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.type === OurProductModel.OUR_PRODUCT_TYPE.PLAN && item.id === 2; });
    var subProduct = (_b = productsQuery.data) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return item.type === OurProductModel.OUR_PRODUCT_TYPE.SUB_PLAN; });
    var freProduct = (_c = productsQuery.data) === null || _c === void 0 ? void 0 : _c.find(function (item) { return item.type === OurProductModel.OUR_PRODUCT_TYPE.PLAN && item.id === 1; });
    var paymentMethodsQuery = StorePaymentMethodQuery.usePaymentMethods(storeId, { enabled: !!storeId });
    var createSubscription = StorePaymentMethodQuery.useCreateSubscription();
    var isTrial = (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.status) === StoreSubscriptionModel.SUBSCRIPTION_STATUS.TRIAL;
    var isBasicPlan = (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.status) === StoreSubscriptionModel.SUBSCRIPTION_STATUS.ACTIVE;
    var availableTrial = (planProduct === null || planProduct === void 0 ? void 0 : planProduct.id) && StorePaymentMethodQuery.getAvailableTrialByProductId(storeId, planProduct === null || planProduct === void 0 ? void 0 : planProduct.id);
    var _f = useMeasure(), ref = _f[0], measureInfo = _f[1];
    var form = useForm({
        mode: 'onChange'
    });
    var isLarge = measureInfo.width > 1000;
    var values = form.watch();
    var estimateSubscription = StorePaymentMethodQuery.useEstimateSubscriptionByStoreId({
        storeId: storeId,
        planId: values.planId,
        optionId: values.optionId,
        additional: (values.subPlanIds || []).map(function (id) {
            var _a;
            var isPromotion = ((_a = subProduct.find(function (product) { return product.id === id; })) === null || _a === void 0 ? void 0 : _a.options[0].discountRate) === 1;
            return {
                subplanId: id,
                optionId: StorePaymentMethodQuery.getAvailableTrialByProductId(storeId, id) && !isPromotion ? -1 : values.optionId
            };
        })
    }, {
        enabled: !!(values.planId && values.optionId && storeId) && values.planId !== 1,
        cacheTime: 10000,
        staleTime: 10000
    });
    React.useEffect(function () {
        var _a, _b;
        if (currentPlan && productsQuery.data) {
            form.reset({
                planId: currentPlan.ourProductId,
                optionId: ((_a = currentPlan.data) === null || _a === void 0 ? void 0 : _a.productOptionId) === -1 ? 4 : (_b = currentPlan.data) === null || _b === void 0 ? void 0 : _b.productOptionId,
                subPlanIds: currentSubplans.map(function (plan) { return plan.ourProductId; })
                // subPlans: subscription.subSubscriptions?.map((item)=> )
            });
        }
        else if (productsQuery.data) {
            form.reset({
                planId: 2,
                optionId: availableTrial ? -1 : 4,
                subPlanIds: [4]
            });
        }
    }, [currentPlan, productsQuery.data, availableTrial === null || availableTrial === void 0 ? void 0 : availableTrial.data]);
    var handlePlanChange = function (planId) {
        if (planId === 1) {
            form.reset({
                planId: 1,
                optionId: form.getValues('optionId') || 1
                // subPlanIds: []
                // subPlans: subscription.subSubscriptions?.map((item)=> )
            });
        }
        else if (planId === 2) {
            form.reset({
                planId: 2,
                optionId: availableTrial ? -1 : 3,
                subPlanIds: [4, 6]
            });
        }
    };
    var antForm = Form.useForm()[0];
    var updateStore = StoreQuery.useUpdateStore();
    var _g = React.useState(), visible = _g[0], setVisible = _g[1];
    var subscribe = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loader.show();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, createSubscription.mutateAsync(__assign(__assign({}, estimateSubscription.data), { storeId: storeId }))];
                case 2:
                    result = _a.sent();
                    return [4 /*yield*/, storeQuery.refetch()];
                case 3:
                    _a.sent();
                    EventTracker.send('구독갱신', result.subscriptions || []);
                    notification.success({ message: '구독정보갱신 완료' });
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _a.sent();
                    notification.success({ message: error_1.message || '구독정보갱신 중 에러 발생' });
                    return [3 /*break*/, 6];
                case 5:
                    loader.hide();
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var _h = Modal.useModal(), modal = _h[0], context = _h[1];
    var handleSubmit = function (newCreditCard) { return __awaiter(void 0, void 0, void 0, function () {
        var method, price, isTrial_1, checkComplete, hasAdditional, hasTrial, title, content;
        var _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    method = (newCreditCard.id && newCreditCard) || (paymentMethodsQuery.data && paymentMethodsQuery.data[0]);
                    price = (_b = (_a = estimateSubscription.data) === null || _a === void 0 ? void 0 : _a.invoice) === null || _b === void 0 ? void 0 : _b.price;
                    if (!(!!method || price === 0)) return [3 /*break*/, 3];
                    isTrial_1 = (_d = (_c = estimateSubscription.data) === null || _c === void 0 ? void 0 : _c.add) === null || _d === void 0 ? void 0 : _d.find(function (item) { var _a; return item.ourProductId === 2 && ((_a = item.data) === null || _a === void 0 ? void 0 : _a.productOptionId) === -1; });
                    if (!isTrial_1) return [3 /*break*/, 2];
                    return [4 /*yield*/, checkBizNoAndUpdate(props.store, antForm, updateStore)];
                case 1:
                    checkComplete = _g.sent();
                    if (!checkComplete) {
                        return [2 /*return*/];
                    }
                    _g.label = 2;
                case 2:
                    hasAdditional = (_f = (_e = estimateSubscription.data) === null || _e === void 0 ? void 0 : _e.add) === null || _f === void 0 ? void 0 : _f.find(function (item) { var _a; return item.ourProductId !== 2 && ((_a = item.data) === null || _a === void 0 ? void 0 : _a.price) > 0; });
                    hasTrial = (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.status) === StoreSubscriptionModel.SUBSCRIPTION_STATUS.TRIAL;
                    title = hasAdditional ? '부가서비스 결제확인' : '결제 하시겠습니까?';
                    content = hasAdditional
                        ? '부가서비스는 기본 유료플랜과 별도로 추가 과금되는 상품입니다. 부가서비스가 정말 필요하실때만 결제해주세요! 결제하시겠습니까?'
                        : hasTrial
                            ? '남아있는 무료체험 기간은 사라지지 않고 유료플랜 기간에 합산되어 추가됩니다. '
                            : '결제 시 유료 플랜 구독이 시작됩니다.';
                    modal.confirm({
                        title: title,
                        centered: true,
                        content: content,
                        okText: '결제',
                        cancelText: '취소',
                        onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, subscribe()];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }
                    });
                    return [3 /*break*/, 4];
                case 3:
                    setVisible(true);
                    _g.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Layout.Columns, { columns: 24, gutter: isLarge ? 16 : 0, justify: "center", ref: ref },
        context,
        React.createElement(EntireLoader, { open: estimateSubscription.isLoading }),
        React.createElement(Layout.Column, { span: isLarge ? 16 : 24 },
            React.createElement(SubscriptionStatus, { subscription: currentPlan, subplans: currentSubplans }),
            !isTrial && !isBasicPlan && freProduct && (React.createElement(FreePlan, { subscription: currentPlan, form: form, onClick: handlePlanChange, plan: freProduct })),
            planProduct && subProduct && (React.createElement(BasicPlan, { storeId: (_d = props.store) === null || _d === void 0 ? void 0 : _d.id, onClick: handlePlanChange, subscription: currentPlan, subSubscriptions: currentSubplans, form: form, availableTrial: availableTrial, basicPlan: planProduct, subPlans: subProduct }))),
        React.createElement(Layout.Column, { span: isLarge ? 8 : 24 },
            React.createElement(SubscriptionNotice, { isPC: isLarge, subscription: currentPlan, form: form, availableTrial: availableTrial, ourProducts: productsQuery.data, willSubscriptions: estimateSubscription.data, onClick: handleSubmit })),
        React.createElement(CreditCardRegister, { storeId: (_e = props.store) === null || _e === void 0 ? void 0 : _e.id, visible: visible, onClose: function (newCreditCard) {
                setVisible(false);
                if (!!newCreditCard) {
                    handleSubmit(newCreditCard);
                }
            } })));
};
var SubscriptionNotice = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var isPC = props.isPC;
    var notices = [
        '- 결제수단관리 페이지에 등록된 주카드로 결제됩니다.',
        '- 자동결제일에 카드결제가 실패할 경우, 무료플랜으로 자동전환 됩니다.',
        '- 결제카드 변경은 결제수단관리 페이지에서 가능합니다.',
        '- 자동결제 해지를 원하시면 결제수단관리에 등록된 카드를 삭제해주세요.',
        '- 구독해지를 원하실 경우, 결제 예정일 하루 전에 구독을 취소해야 합니다.',
        '- 플랜 다운그레이드 또는 부가서비스 이용취소를 원하시면 채팅상담으로 문의주세요.'
    ];
    var newPlanId = props.form.getValues('planId');
    var newOptionId = props.form.getValues('optionId');
    var newAdditional = props.form.getValues('subPlanIds');
    var isDifferent = (newPlanId !== 1 && !isEmpty((_a = props.willSubscriptions) === null || _a === void 0 ? void 0 : _a.add)) || !isEmpty((_b = props.willSubscriptions) === null || _b === void 0 ? void 0 : _b.remove);
    var commons = function () {
        return (React.createElement(React.Fragment, null,
            notices.map(function (notice, index) { return (React.createElement(Typhography.TextComponent, { type: "caption1", color: COLORS.gray2, key: "notice_".concat(index) }, notice)); }),
            React.createElement(TransferNotice, { onClick: function () {
                    return openWindow('https://help.thinkofyou.kr/0e4afadb-c65d-432e-99c8-4e1a86eca256', '무통장입금 안내');
                } },
                React.createElement(Typhography.TextComponent, { type: "subtitle3", color: COLORS.calendarRedActive, marginRight: 5, style: { width: '40px' } }, "[\uCC38\uACE0]"),
                React.createElement(Typhography.TextComponent, { type: "subtitle3" }, "\uCE74\uB4DC\uACB0\uC81C\uB97C \uD560 \uC218 \uC5C6\uB2E4\uBA74? \uBB34\uD1B5\uC7A5 \uC785\uAE08\uC73C\uB85C \uACB0\uC81C\uD574\uBCF4\uC138\uC694! >"))));
    };
    var SubmitButton = function (price) {
        return (React.createElement(SubmitButtonWrapper, null,
            React.createElement(Layout.FluidInline, { align: "start", stretch: 0, style: { marginBottom: '10px' } },
                React.createElement(Typhography.TextComponent, { type: "headline3" }, "\uD569\uACC4"),
                React.createElement(Typhography.TextComponent, { type: "headline3" }, KRWFomatToString({ value: price }))),
            React.createElement(AntButton, { children: "\uAD6C\uB3C5 \uC124\uC815 \uBCC0\uACBD", onClick: props.onClick, type: "primary" })));
    };
    var RenderSubscription = function (subscription, remove) {
        var _a, _b;
        var price = ((_a = subscription === null || subscription === void 0 ? void 0 : subscription.data) === null || _a === void 0 ? void 0 : _a.price) + ((_b = subscription === null || subscription === void 0 ? void 0 : subscription.data) === null || _b === void 0 ? void 0 : _b.vat);
        return (React.createElement(Layout.FluidInline, { align: "start", stretch: 0, key: "".concat(subscription.data.productOptionId, "_").concat(subscription.ourProductId) },
            React.createElement("div", { style: { marginBottom: '5px' } },
                React.createElement(Typhography.TextComponent, { type: remove ? 'headline3' : 'headline3' }, subscription.name),
                !remove && (React.createElement(Typhography.TextComponent, { type: "caption1", color: COLORS.info },
                    dayjs(subscription.startedAt).format(DATE_FORMAT),
                    " ~",
                    dayjs(remove ? dayjs() : subscription.expiredAt).format(DATE_FORMAT)))),
            React.createElement(Typhography.TextComponent, { type: "headline3" }, KRWFomatToString({ value: price }))));
    };
    // 베이식 플랜을 처음 체험하는 고객 (무료 체험 신청)
    if (!props.subscription && props.availableTrial && newPlanId === 2 && !isEmpty((_c = props.willSubscriptions) === null || _c === void 0 ? void 0 : _c.add)) {
        return (React.createElement(PaymentNoticeWrapper, { "$isPC": isPC },
            React.createElement(PaymentInfoWrapper, { "$isPC": isPC },
                React.createElement(PaymentInfoContent, null, (_d = props.willSubscriptions.add) === null || _d === void 0 ? void 0 : _d.map(function (subscription, index) {
                    return RenderSubscription(subscription);
                })),
                SubmitButton(((_f = (_e = props.willSubscriptions) === null || _e === void 0 ? void 0 : _e.invoice) === null || _f === void 0 ? void 0 : _f.totalPrice) + ((_h = (_g = props.willSubscriptions) === null || _g === void 0 ? void 0 : _g.invoice) === null || _h === void 0 ? void 0 : _h.totalVat))),
            commons()));
    }
    return isDifferent ? (React.createElement(PaymentNoticeWrapper, { "$isPC": isPC },
        React.createElement(GrayCard, null,
            React.createElement(PaymentInfoContent, null,
                React.createElement(Typhography.TextComponent, { type: "subtitle2", color: COLORS.gray3, marginBottom: 5 }, "\uCDE8\uC18C\uB420 \uAD6C\uB3C5"), (_k = (_j = props.willSubscriptions) === null || _j === void 0 ? void 0 : _j.remove) === null || _k === void 0 ? void 0 :
                _k.map(function (item) { return RenderSubscription(item, true); }),
                React.createElement(Typhography.TextComponent, { color: COLORS.gray3, marginBottom: 5, marginTop: 10 }, "\uC2E0\uADDC \uAD6C\uB3C5"), (_m = (_l = props.willSubscriptions) === null || _l === void 0 ? void 0 : _l.add) === null || _m === void 0 ? void 0 :
                _m.map(function (item) { return RenderSubscription(item); })),
            SubmitButton(((_p = (_o = props.willSubscriptions) === null || _o === void 0 ? void 0 : _o.invoice) === null || _p === void 0 ? void 0 : _p.totalPrice) + ((_r = (_q = props.willSubscriptions) === null || _q === void 0 ? void 0 : _q.invoice) === null || _r === void 0 ? void 0 : _r.totalVat))),
        commons())) : (React.createElement(PaymentNoticeWrapper, { "$isPC": isPC },
        React.createElement(GrayCard, null,
            React.createElement(Icon.Icon, { name: "file-text", color: COLORS.gray4, size: 32 }),
            React.createElement(Typhography.TextComponent, { type: "subtitle2", color: COLORS.gray4, marginTop: 12 }, "\uC124\uC815\uC744 \uBCC0\uACBD\uD558\uBA74 \uACB0\uC81C\uB0B4\uC6A9\uC774 \uBBF8\uB9AC \uBCF4\uC5EC\uC9D1\uB2C8\uB2E4.")),
        commons()));
};
var PaymentInfoContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: start;\n  justify-items: space-between;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: start;\n  justify-items: space-between;\n  flex-direction: column;\n  width: 100%;\n"])));
var SubmitButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n  width: 100%;\n  & > button {\n    width: 100%;\n  }\n"], ["\n  border-top: 1px solid ", ";\n  width: 100%;\n  & > button {\n    width: 100%;\n  }\n"])), COLORS.gray7);
var PaymentNoticeWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: ", ";\n"], ["\n  margin-top: ", ";\n"])), function (props) { return (props.$isPC ? '0px' : '10px'); });
var PaymentInfoWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  min-width: 320px;\n  min-height: 170px;\n  border-radius: 2px;\n  border: solid 1px ", ";\n  background-color: ", ";\n  margin-bottom: ", ";\n  margin-top: ", ";\n  display: flex;\n  align-items: center;\n  justify-items: center;\n  flex-direction: column;\n"], ["\n  min-width: 320px;\n  min-height: 170px;\n  border-radius: 2px;\n  border: solid 1px ", ";\n  background-color: ", ";\n  margin-bottom: ", ";\n  margin-top: ", ";\n  display: flex;\n  align-items: center;\n  justify-items: center;\n  flex-direction: column;\n"])), COLORS.gray7, COLORS.gray7, function (props) { return (props.$isPC ? '10px' : '20px'); }, function (props) { return (props.$isPC ? '0px' : '20px'); });
var TransferNotice = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 10px;\n  cursor: pointer;\n  padding: 1px 71px 1px 4px;\n  border-radius: 2px;\n  background-color: rgba(255, 77, 79, 0.05);\n  display: flex;\n  padding-top: 2px;\n  padding-bottom: 2px;\n"], ["\n  margin-top: 10px;\n  cursor: pointer;\n  padding: 1px 71px 1px 4px;\n  border-radius: 2px;\n  background-color: rgba(255, 77, 79, 0.05);\n  display: flex;\n  padding-top: 2px;\n  padding-bottom: 2px;\n"])));
var SubscriptionStatus = function (props) {
    var _a, _b, _c, _d;
    var isPC = useWindowSize().isPC;
    var renderLabelAndValue = function (label, value, key) {
        return (React.createElement(Col, { key: key, span: isPC ? 12 : 24 },
            React.createElement(Row, null,
                React.createElement(Typhography.TextComponent, { type: "subtitle2", color: COLORS.gray3, style: { width: '100px' } }, label),
                value)));
    };
    var columns = props.subscription
        ? [
            {
                label: '현재 구독중',
                value: (React.createElement("div", null,
                    React.createElement(Layout.Inline, { gutter: 4 },
                        React.createElement(CommonImages.BasicPlanIcon, null),
                        React.createElement(Typhography.TextComponent, { type: "subtitle2" }, (_a = props.subscription) === null || _a === void 0 ? void 0 : _a.name)),
                    React.createElement(Typhography.TextComponent, { color: COLORS.info, type: "caption2" }, (_b = props.subscription) === null || _b === void 0 ? void 0 : _b.startedAtAndexpiredAtString)))
            },
            isPC && {
                label: '다음 결제 예정일',
                value: (React.createElement(Typhography.TextComponent, { type: "subtitle2" }, dayjs((_c = props.subscription) === null || _c === void 0 ? void 0 : _c.expiredAt)
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD')))
            },
            {
                label: '부가 서비스',
                value: isEmpty(props.subplans) ? (React.createElement(Typhography.TextComponent, { type: "subtitle2" }, "\uC5C6\uC74C")) : (React.createElement("div", null, props.subplans.map(function (sub) {
                    return (React.createElement("div", { key: sub.id },
                        React.createElement(Layout.Inline, { gutter: 4 },
                            React.createElement(Typhography.TextComponent, { type: "subtitle2" }, sub === null || sub === void 0 ? void 0 : sub.name)),
                        React.createElement(Typhography.TextComponent, { color: COLORS.info, type: "caption2" }, sub === null || sub === void 0 ? void 0 : sub.startedAtAndexpiredAtString)));
                })))
            },
            !isPC && {
                label: '다음 결제 예정일',
                value: (React.createElement(Typhography.TextComponent, { type: "subtitle2" }, dayjs((_d = props.subscription) === null || _d === void 0 ? void 0 : _d.expiredAt)
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD')))
            }
        ].filter(Boolean)
        : [
            {
                label: '현재 구독중',
                value: (React.createElement(Layout.Inline, { gutter: 4 },
                    React.createElement(CommonImages.FreePlanIcon, null),
                    React.createElement(Typhography.TextComponent, { type: "headline3" }, "\uBB34\uB8CC \uD50C\uB79C")))
            }
        ];
    return (React.createElement(Card, { style: { background: COLORS.gray7 } },
        React.createElement(Row, { gutter: 8 }, columns.map(function (item, index) {
            return renderLabelAndValue(item.label, item.value, index);
        }))));
};
var SubscriptionTitle = function (props) {
    var isFree = props.type === 'free';
    return (React.createElement(Layout.Inline, { align: "center" },
        isFree ? React.createElement(CommonImages.FreePlanIcon, null) : React.createElement(CommonImages.BasicPlanIcon, null),
        React.createElement(Typhography.TextComponent, { type: "headline2", marginLeft: 10, marginRight: 10 }, isFree ? '무료 플랜' : '유료 플랜')));
};
var FreePlan = function (props) {
    var active = props.form.getValues('planId') === props.plan.id;
    return (React.createElement(Wrapper, { "$active": props.form.getValues('planId') === props.plan.id, onClick: function (ev) {
            var _a;
            if (!active) {
                ev.stopPropagation();
                ev.preventDefault();
                props.onClick((_a = props.plan) === null || _a === void 0 ? void 0 : _a.id);
            }
        } },
        React.createElement(SubscriptionTitle, { type: "free" }),
        React.createElement(Typhography.TextComponent, { type: "subtitle2", marginTop: 10 }, "\uC0C1\uC810\uAD00\uB9AC \uAE30\uBCF8\uAE30\uB2A5\uC744 PC\uB85C \uC774\uC6A9, 1\uC778\uC0F5 \uC0AC\uC6A9\uAC00\uB2A5")));
};
var BasicPlan = function (props) {
    var _a, _b, _c, _d;
    var features = [
        {
            title: '고객관리 핵심기능'
        },
        {
            title: '문자∙알림톡 자동발송',
            description: '(문자비용 별도)'
        },
        {
            title: '네이버예약 연동'
        },
        {
            title: 'PC/모바일 앱 실시간 연동'
        }
    ];
    var optionId = props.form.watch('optionId');
    var additionals = props.subPlans
        .map(function (sub) {
        var subOption = sub.options.find(function (item) { return item.id === optionId; });
        var total = floor(((subOption === null || subOption === void 0 ? void 0 : subOption.price) + (subOption === null || subOption === void 0 ? void 0 : subOption.vat)) / (subOption === null || subOption === void 0 ? void 0 : subOption.quantity));
        var isPromotion = (subOption === null || subOption === void 0 ? void 0 : subOption.discountRate) === 100;
        var availableTrial = (sub === null || sub === void 0 ? void 0 : sub.id) && StorePaymentMethodQuery.getAvailableTrialByProductId(props.storeId, sub === null || sub === void 0 ? void 0 : sub.id);
        return subOption
            ? {
                label: subOption ? (React.createElement(Layout.FluidInline, { justify: "start", stretch: 2 },
                    React.createElement(Typhography.TextComponent, null, sub.displayName),
                    React.createElement(GuideIconOnlyLink, { link: sub.id === 4
                            ? 'https://oxidized-duke-790.notion.site/1580d600095448f697ee76751d5d5838'
                            : sub.id === 5
                                ? 'https://www.notion.so/70323cc50c0544d3a39991f2fb6d9bc4'
                                : 'https://www.notion.so/bf1702c7ca03472dba7914478ab38004' }),
                    React.createElement("div", null),
                    isPromotion && React.createElement(ColorTag, { color: COLORS.calendarRedActive, children: "\uB7F0\uCE6D \uD504\uB85C\uBAA8\uC158 \uD560\uC778" }),
                    !isPromotion && availableTrial && React.createElement(ColorTag, { color: COLORS.info, children: "30\uC77C \uBB34\uB8CC\uCCB4\uD5D8" }),
                    (isPromotion || availableTrial) && (React.createElement(StrikeText, { color: COLORS.gray4, type: "caption1" },
                        total.toLocaleString(),
                        "\uC6D0")),
                    React.createElement(Typhography.TextComponent, { type: "headline3" },
                        "\uC6D4 ",
                        availableTrial || isPromotion ? 0 : total.toLocaleString(),
                        "\uC6D0"))) : (React.createElement("div", null)),
                value: sub.id
            }
            : null;
    })
        .filter(Boolean);
    var handleChangeCheck = function (ev, value) {
        var prevSubPlanIds = props.form.getValues('subPlanIds') || [];
        props.form.setValue('subPlanIds', ev.target.checked ? prevSubPlanIds.concat(value) : props.form.getValues('subPlanIds').filter(function (id) { return id !== value; }));
    };
    var active = props.form.getValues('planId') === props.basicPlan.id;
    var isMainPlanPromotion = optionId === 4;
    return (React.createElement(Wrapper, { "$active": props.form.getValues('planId') === props.basicPlan.id, onClick: function (ev) {
            var _a;
            if (!active) {
                ev.stopPropagation();
                ev.preventDefault();
                props.onClick((_a = props.basicPlan) === null || _a === void 0 ? void 0 : _a.id);
            }
        } },
        React.createElement(SubscriptionTitle, { type: "basic" }),
        React.createElement(Typhography.TextComponent, { type: "subtitle2", marginTop: 10, marginBottom: 16 }, "\uB354 \uB9CE\uC740 \uD575\uC2EC\uAE30\uB2A5\uC744 \uB2E4\uC591\uD55C \uAE30\uAE30\uB85C \uC774\uC6A9, 1\uC778\uC0F5~\uB2E4\uC778\uC0F5 \uC0AC\uC6A9\uAC00\uB2A5"),
        React.createElement(GrayCard, null,
            React.createElement(UnorderedList, null, features.map(function (feature) { return (React.createElement("li", { key: feature.title },
                React.createElement(TextComponent, null, feature.title),
                feature.description && React.createElement(TextComponent, { color: COLORS.gray3 }, feature.description))); }))),
        React.createElement(HookFormInputs.SelectInput, { inputProps: {
                placeholder: '구독 기간 설정',
                options: [
                    (props.availableTrial ||
                        ((_a = props.subscription) === null || _a === void 0 ? void 0 : _a.status) === StoreSubscriptionModel.SUBSCRIPTION_STATUS.TRIAL ||
                        ((_c = (_b = props.subscription) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.productOptionId) === -1) && {
                        label: '30일 무료체험',
                        value: -1
                    }
                ]
                    .concat((_d = props.basicPlan.options
                    .filter(function (item) { return item.id !== -1; })) === null || _d === void 0 ? void 0 : _d.map(function (option) {
                    return {
                        label: (React.createElement(Layout.FluidInline, { stretch: 0, style: {
                                height: '100%',
                                alignItems: 'center'
                            } },
                            React.createElement(Typhography.TextComponent, null, "".concat(option.quantity, "\uAC1C\uC6D4")),
                            option.discountRate ? (React.createElement(ColorTag, { color: COLORS.calendarRedActive, children: "".concat(floor(option.discountRate), "%") })) : (''),
                            React.createElement(Typhography.TextComponent, { marginLeft: 5 },
                                "\uC6D4",
                                KRWFomatToString({
                                    value: floor((((option.price + option.vat) / option.quantity) * (100 - option.discountRate)) / 100)
                                }),
                                "(VAT\uD3EC\uD568)"))),
                        value: option.id
                    };
                }).filter(function (item) {
                    var _a, _b, _c, _d;
                    return !((_b = (_a = props.subscription) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.productOptionId) ||
                        item.value >= ((_d = (_c = props.subscription) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.productOptionId);
                }))
                    .filter(Boolean)
            }, controlProps: {
                name: "optionId",
                control: props.form.control
            } }),
        isMainPlanPromotion && (React.createElement(Typhography.TextComponent, { type: "caption1", color: COLORS.primary1 }, "2\uB144\uACB0\uC81C \uD2B9\uBCC4\uD504\uB85C\uBAA8\uC158(~2025.03.31)")),
        React.createElement(Typhography.TextComponent, { type: "headline3", color: COLORS.gray4, marginTop: 16, marginBottom: 10 }, "\uBD80\uAC00\uC11C\uBE44\uC2A4"),
        React.createElement(SubplanCheckboxWrapper, null, additionals.map(function (additional) {
            var _a, _b;
            return (React.createElement(HookFormInputs.CheckboxInput, { key: "subPlanIds_".concat(additional.value), inputProps: {
                    onChange: function (ev) { return handleChangeCheck(ev, additional.value); },
                    checked: (_a = props.form.getValues('subPlanIds')) === null || _a === void 0 ? void 0 : _a.includes(additional.value),
                    disabled: !!((_b = props.subSubscriptions) === null || _b === void 0 ? void 0 : _b.find(function (sub) {
                        return sub.ourProductId === additional.value &&
                            sub.status !== StoreSubscriptionModel.SUBSCRIPTION_STATUS.TRIAL;
                    })),
                    children: additional.label,
                    value: additional.value
                }, controlProps: {
                    name: "subPlanIds_".concat(additional.value),
                    control: props.form.control
                } }));
        }))));
};
var StrikeText = styled(Typhography.TextComponent)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  text-decoration: line-through;\n  margin-left: 5px;\n  margin-right: 5px;\n"], ["\n  text-decoration: line-through;\n  margin-left: 5px;\n  margin-right: 5px;\n"])));
var SubplanCheckboxWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  & label {\n    width: 100%;\n    & > span:last-child {\n      width: 100%;\n    }\n  }\n"], ["\n  width: 100%;\n  & label {\n    width: 100%;\n    & > span:last-child {\n      width: 100%;\n    }\n  }\n"])));
var UnorderedList = styled.ul(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding-left: 8px;\n  list-style: none;\n  margin-bottom: 0px;\n\n  & li {\n    display: flex;\n    align-items: center;\n    &::before {\n      content: '\u2022 ';\n      color: ", ";\n      margin-right: 8px;\n    }\n  }\n"], ["\n  padding-left: 8px;\n  list-style: none;\n  margin-bottom: 0px;\n\n  & li {\n    display: flex;\n    align-items: center;\n    &::before {\n      content: '\u2022 ';\n      color: ", ";\n      margin-right: 8px;\n    }\n  }\n"])), COLORS.primary1);
var Wrapper = styled(Card)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  opacity: ", ";\n  margin-top: 20px;\n\n  &.ant-card-bordered {\n    border: solid ", ";\n  }\n\n  & > div > .ant-select {\n    margin-top: 16px;\n    width: 100%;\n  }\n  cursor: ", ";\n"], ["\n  opacity: ", ";\n  margin-top: 20px;\n\n  &.ant-card-bordered {\n    border: solid ", ";\n  }\n\n  & > div > .ant-select {\n    margin-top: 16px;\n    width: 100%;\n  }\n  cursor: ", ";\n"])), function (props) { return (props.$active ? 1 : 0.5); }, function (props) { return (props.$active ? "1px ".concat(COLORS.primary1, ";") : "0px ".concat(COLORS.gray3, ";")); }, function (props) { return (props.$active ? 'unset' : 'pointer'); });
export var StoreSubscriptionPopup = function (props) {
    var store = StoreQuery.getMyStoreInCache();
    var loginManager = ManagerQuery.getLoginManagerInCache();
    var commons = {
        key: MANAGER_PERMISSION_KEYS.STORE_MANAGEMENT,
        priority: MANAGER_PERMISSION_PRIORITY.ALL
    };
    var update = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission(__assign(__assign({}, commons), { action: MANAGER_PERMISSION_ACTIONS.UPDATE }));
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    return (React.createElement(React.Fragment, null,
        React.cloneElement(props.trigger, {
            onClick: function (ev) {
                ev.stopPropagation();
                ev.preventDefault();
                setOpen(true);
            }
        }),
        React.createElement(EntireBigTitleModal, { open: open, footer: null, onCancel: function () { return setOpen(false); }, children: React.createElement("div", { style: { padding: '20px', height: '100%' } },
                React.createElement(ScrollBarWithMeasure, { options: {
                        overflowBehavior: {
                            x: 'hidden'
                        }
                    } },
                    React.createElement(NewStoreSubscription, { store: store, permission: { update: update } }))), title: "\uAD6C\uB3C5 \uBC0F \uACB0\uC81C" })));
};
var GrayCard = styled(Card)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background: ", ";\n"], ["\n  background: ", ";\n"])), COLORS.gray7);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
