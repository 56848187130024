import memoize from 'lodash-es/memoize';
import * as Instance from 'react-gtm-module';
import { NODE_ENV } from 'renderer/constants';
export var initTagManager = memoize(function () {
    if (NODE_ENV !== 'production') {
        return;
    }
    Instance.initialize({
        gtmId: 'GTM-T5PR2PX'
    });
});
export var cleanDetachedElements = function () {
    var dataLayer = window['dataLayer'];
    if (!dataLayer || !Array.isArray(dataLayer)) {
        return;
    }
    // dataLayer를 필터링하여 detached 요소 제거
    window['dataLayer'] = dataLayer.filter(function (item) {
        var gtmElement = item['gtm.element'];
        // 요소가 DOM에 연결되어 있는지 확인
        if (!gtmElement || !document.body.contains(gtmElement)) {
            return false; // 제거
        }
        return true; // 유지
    });
};
export { Instance };
