var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Row from 'antd/lib/row';
import { CustomerGroupQuery, CustomerQuery, ManagerQuery, StoreQuery } from 'renderer/queries';
import { CardWithTabs, ColorTag, TableComponent, TextComponent, StyledDivider, SendMessageModal, FilterWithSearcher, CustomDropdown, Icon, TableSetterDefaultTrigger, typographyByName, DrawerComponent, AntdForm, DrawerFooter, SortableTables } from 'renderer/components';
import styled from 'styled-components';
import { COLORS, MediaQuery } from 'renderer/styles';
import { CustomerModel, MANAGER_PERMISSION_KEYS, MANAGER_PERMISSION_PRIORITY, MANAGER_PERMISSION_ACTIONS } from 'renderer/models';
import * as dayjs from 'dayjs';
import { KRWFomatToString } from 'renderer/components';
import { isArray, Lodash, useWindowSize, isSafeInteger, createUUID, isNilOrEmptyArray, uniq, addOrRemove, isEmpty, isValidDateString } from 'renderer/utils';
import { DATE_FORMAT } from 'renderer/constants';
import { CustomerActions } from './CustomerList';
import isEqual from 'lodash-es/isEqual';
import notification from 'antd/lib/notification';
import Modal from 'antd/lib/modal';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useMeasure } from 'react-use';
import { Layout } from 'common-ui';
import { Button, Form } from 'antd/lib';
export var isValidFilter = function (filters) {
    return Lodash.every((filters || []).map(function (and) {
        return Lodash.every(and.or.map(function (orItem) {
            return orItem.key &&
                orItem.operator &&
                orItem.type &&
                (orItem.values || orItem.values === false || Lodash.isNumber(orItem.values));
        }));
    }));
};
export var CustomerTableColumns = function (availableSort) { return [
    {
        title: '고객번호',
        dataIndex: 'customerNumber',
        key: 'customerNumber',
        width: 80,
        fixed: 'left',
        sorter: availableSort
    },
    {
        title: '고객명',
        dataIndex: 'name',
        key: 'name',
        width: 80,
        fixed: 'left',
        sorter: availableSort,
        ellipsis: {
            showTitle: false
        }
    },
    {
        title: '연락처',
        dataIndex: 'phone',
        key: 'phone',
        width: 150,
        render: function (_, row) {
            return row.phoneToString;
        }
    },
    {
        title: '메모',
        dataIndex: ['memo'],
        key: 'memo',
        width: 180,
        ellipsis: {
            showTitle: false
        }
    },
    {
        title: '담당자',
        dataIndex: ['manager', 'profile', 'name'],
        key: 'manager.name',
        width: 100
    },
    {
        title: '등급',
        dataIndex: ['customerGroup', 'name'],
        key: 'customerGroup',
        width: 100
    },
    {
        title: '방문횟수',
        dataIndex: ['meta', 'numberOfUsing'],
        key: 'meta.numberOfUsing',
        width: 100,
        sorter: availableSort,
        render: function (value) { return (!value ? '0건' : "".concat(value, "\uAC74")); }
    },
    {
        title: '잔여 포인트',
        dataIndex: ['meta', 'customerPoint'],
        key: 'meta.customerPoint',
        width: 110,
        sorter: availableSort,
        render: function (value) { return KRWFomatToString({ value: value }); }
    },
    {
        title: '잔여 선불액',
        dataIndex: ['meta', 'prepaid'],
        key: 'meta.prepaid',
        width: 110,
        sorter: availableSort,
        render: function (value) { return KRWFomatToString({ value: value }); }
    },
    {
        title: '실 매출액',
        dataIndex: ['meta', 'totalPaid'],
        key: 'meta.totalPaid',
        sorter: availableSort,
        render: function (value) { return KRWFomatToString({ value: value }); },
        width: 120
    },
    {
        title: '최근 방문일',
        dataIndex: ['meta', 'lastVisitedAt'],
        key: 'lastVisitedAt',
        sorter: availableSort,
        width: 110,
        render: function (value) { return (value ? dayjs(value).format(DATE_FORMAT) : '-'); }
    },
    // {
    //   title: '방문횟수',
    //   dataIndex: ['meta', 'numberOfUsing'],
    //   key: 'numberOfUsing',
    //   render: (value) => (!value ? '0회' : `${value}회`),
    //   width: 100
    // },
    // {
    //   title: '노쇼',
    //   dataIndex: ['meta', 'numberOfNoshow'],
    //   key: 'numberOfNoshow',
    //   render: (value) => (!value ? '0회' : `${value}회`),
    //   width: 100
    // },
    // {
    //   title: '미수',
    //   dataIndex: ['meta', 'unpaid'],
    //   key: 'unpaid',
    //   render: (value) => KRWFomatToString({ value }),
    //   width: 100
    // },
    {
        title: '태그',
        width: 200,
        key: 'tags',
        render: function (_, record) {
            var tags = record.tags;
            return (!Lodash.isEmpty(tags) && (React.createElement(Row, null, tags.map(function (tag) { return (React.createElement(ColorTag, { color: tag.color, children: tag.name, key: tag.id })); }))));
        }
    },
    {
        title: '생일',
        dataIndex: ['birth'],
        key: 'birth',
        width: 110,
        render: function (value) { return (value ? dayjs(value).format(DATE_FORMAT) : '-'); }
    }
]; };
export var CustomerTab = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var storeResponse = StoreQuery.useStore();
    var store = storeResponse.data;
    if (!store) {
        return React.createElement("div", null);
    }
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var _l = useWindowSize(), isPC = _l.isPC, height = _l.height;
    var DEFAULT_TABS = [{ tab: '전체', key: 'all' }];
    React.useEffect(function () {
        var newFilters = (store === null || store === void 0 ? void 0 : store.filters) || [];
        var prevTabs = tabs;
        var isAdd = prevTabs.length - 1 < newFilters.length;
        var isDeleted = prevTabs.length - 1 > newFilters.length;
        if (isAdd) {
            var addded_1 = newFilters[newFilters.length - 1];
            setTabs(tabs.concat({ tab: addded_1.name, key: addded_1.id, color: addded_1.color }));
            var filter_1 = filters.find(function (item) {
                return "".concat(item.id) === addded_1.id;
            });
            handleTabChange(addded_1.id, filter_1);
        }
        else if (isDeleted) {
            var newTab = [{ tab: '전체', key: 'all' }].concat(filters.map(function (item) { return ({ tab: item.name, key: item.id, color: item.color || COLORS.segment1 }); }));
            setActiveTab('all');
            setFilter(null);
            setNewPagerOptions(__assign(__assign({}, query), { filter: [] }));
            return setTabs(newTab);
        }
        else {
            var newTab = [{ tab: '전체', key: 'all' }].concat(filters.map(function (item) { return ({ tab: item.name, key: item.id, color: item.color || COLORS.segment1 }); }));
            if (!isEqual(newTab, prevTabs)) {
                return setTabs(newTab);
            }
        }
    }, [store.filters]);
    var _m = React.useState({
        isAll: false,
        customerIds: []
    }), customerTableSelectData = _m[0], setCustomerTableSelectData = _m[1];
    var loginManager = ManagerQuery.getLoginManagerInCache();
    var tagQuery = CustomerQuery.useCustomerTags(storeId, { enabled: !!storeId });
    var tagOptions = (_a = tagQuery.data) === null || _a === void 0 ? void 0 : _a.map(function (tag) { return ({ label: tag.name, value: tag.id }); });
    var groupQuery = CustomerGroupQuery.useCustomerGroups(storeId, { enabled: !!storeId });
    var groupOptions = (_b = groupQuery.data) === null || _b === void 0 ? void 0 : _b.map(function (group) { return ({ label: group.name, value: group.id }); });
    var allPerm = loginManager === null || loginManager === void 0 ? void 0 : loginManager.hasPermission({
        key: MANAGER_PERMISSION_KEYS.CUSTOMER_LIST,
        priority: MANAGER_PERMISSION_PRIORITY.ALL,
        action: MANAGER_PERMISSION_ACTIONS.READ
    });
    var handleSelectDataChange = function (data) {
        setCustomerTableSelectData(data);
    };
    var filters = (store === null || store === void 0 ? void 0 : store.filters) || [];
    var _o = React.useState(DEFAULT_TABS.concat(filters.map(function (item) { return ({ tab: item.name, key: item.id, closable: true, color: item.color || COLORS.segment1 }); }))), tabs = _o[0], setTabs = _o[1];
    var getFilterInTab = function (activeTab) {
        return (activeTab.key !== 'all' &&
            filters.find(function (item) {
                var _a;
                return "".concat(item.id) === "".concat((_a = tabs.find(function (tab) { return tab.key == activeTab; })) === null || _a === void 0 ? void 0 : _a.key);
            }));
    };
    var _p = React.useState('all'), activeTab = _p[0], setActiveTab = _p[1];
    var isAll = activeTab === 'all';
    var _q = React.useState(getFilterInTab(activeTab)), filter = _q[0], setFilter = _q[1];
    var convertCustomerFilterToFilterData = function (filter) {
        var _a, _b;
        var condition = (filter === null || filter === void 0 ? void 0 : filter.condition) || 'intersection';
        var isUnion = condition === 'union';
        var values = {};
        var dataToValue = function (data) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            switch (data.type) {
                case 'MMDDRange':
                    return {
                        start: data.values && data.values.start,
                        end: data.values && data.values.end
                    };
                // enum eq => in으로 변경해야함
                case 'enum':
                    return isArray(data.values) ? data.values : [data.values];
                // text string $like
                case 'string':
                    return data.values;
                // range
                case 'number':
                    var values_1 = data.values;
                    return data.operator === '$between'
                        ? values_1
                        : data.operator === '$gte'
                            ? { start: values_1, end: null }
                            : { start: null, end: values_1 };
                case 'date':
                    // FIXME: date가 dateRange와 dateWithOperator 두개로 나뉘어있음
                    return data.key === 'birth'
                        ? {
                            start: ((_a = data.values) === null || _a === void 0 ? void 0 : _a.start) ? dayjs((_b = data.values) === null || _b === void 0 ? void 0 : _b.start) : null,
                            end: ((_c = data.values) === null || _c === void 0 ? void 0 : _c.end) ? dayjs((_d = data.values) === null || _d === void 0 ? void 0 : _d.end) : null
                        }
                        : {
                            operator: data.operator,
                            value: data.values && data.operator === '$between'
                                ? {
                                    start: ((_e = data.values) === null || _e === void 0 ? void 0 : _e.start) ? dayjs((_f = data.values) === null || _f === void 0 ? void 0 : _f.start) : null,
                                    end: ((_g = data.values) === null || _g === void 0 ? void 0 : _g.end) ? dayjs((_h = data.values) === null || _h === void 0 ? void 0 : _h.end) : null
                                }
                                : typeof data.values === 'number'
                                    ? data.values
                                    : dayjs(data.values)
                        };
            }
        };
        if (isUnion) {
            (_a = filter === null || filter === void 0 ? void 0 : filter.data) === null || _a === void 0 ? void 0 : _a.and.map(function (item) {
                var _a;
                (_a = item.or) === null || _a === void 0 ? void 0 : _a.map(function (orItem) {
                    if (orItem) {
                        values[orItem.key] = dataToValue(orItem);
                    }
                });
            });
        }
        else {
            (_b = filter === null || filter === void 0 ? void 0 : filter.data) === null || _b === void 0 ? void 0 : _b.and.map(function (item) {
                var orItem = item.or && item.or[0];
                if (orItem) {
                    values[orItem.key] = dataToValue(orItem);
                }
            });
        }
        return __assign({ condition: condition }, values);
    };
    var handleTabChange = function (key, tabData) {
        setActiveTab(key);
        tabData = getFilterInTab(key) || tabData;
        setFilter(tabData);
        handleChangeKeywordOrFilter({
            page: 1,
            limit: 10,
            storeId: storeId,
            search: '',
            filter: tabData
        });
    };
    var handleChangeKeywordOrFilter = function (pagerOption) {
        setNewPagerOptions(pagerOption);
        handleSelectDataChange({
            isAll: false,
            customerIds: []
        });
    };
    var _r = CustomerQuery.useCustomerPager({
        page: 1,
        limit: 10,
        storeId: storeId,
        search: '',
        filter: filter,
        managerId: allPerm ? undefined : loginManager.id
    }, { enabled: !!storeId }), pagerResult = _r.pagerResult, isLoading = _r.isLoading, getPaginateObject = _r.getPaginateObject, pagerOptions = _r.pagerOptions, setNewPagerOptions = _r.setNewPagerOptions, query = _r.query;
    var handleTableSortAndPagerChange = function (pagination, filters, sorter) {
        var _a;
        setNewPagerOptions({
            storeId: storeId,
            search: pagerOptions.search || '',
            filter: filter,
            managerId: allPerm ? undefined : loginManager.id,
            sortBy: ((_a = sorter === null || sorter === void 0 ? void 0 : sorter.column) === null || _a === void 0 ? void 0 : _a.key) || 'createdAt',
            sortDir: (sorter === null || sorter === void 0 ? void 0 : sorter.order) || 'desc',
            page: pagination.current,
            limit: pagination.pageSize
        });
        // handleSelectDataChange({
        //   isAll: false,
        //   customerIds: []
        // });
    };
    var _s = React.useState(false), setterVisible = _s[0], setSetterVisible = _s[1];
    var _t = useMeasure(), wrapperRef = _t[0], _u = _t[1], x = _u.x, y = _u.y, width = _u.width, top = _u.top, right = _u.right, bottom = _u.bottom, left = _u.left;
    var scroll = { x: width };
    var workingManagers = ManagerQuery.getWorkingManagersInCache();
    var totalQuery = StoreQuery.useGetSMSAvailableIds({ filter: pagerOptions.filter, search: pagerOptions.search });
    var selectedCount = customerTableSelectData.isAll
        ? (((_d = (_c = totalQuery.data) === null || _c === void 0 ? void 0 : _c.ids) === null || _d === void 0 ? void 0 : _d.length) || 0) - ((customerTableSelectData === null || customerTableSelectData === void 0 ? void 0 : customerTableSelectData.customerIds.length) || 0)
        : ((_e = customerTableSelectData === null || customerTableSelectData === void 0 ? void 0 : customerTableSelectData.customerIds) === null || _e === void 0 ? void 0 : _e.length) || 0;
    return (React.createElement(StyledCardWithTabs, { tabList: tabs, tabBarExtraContent: !isPC ? (React.createElement(CustomerActions, { isPC: isPC, onClickDrawer: props.onClickDrawer, onClickRegister: props.onClickRegister, storeId: storeId })) : null, activeTabKey: activeTab, onTabChange: handleTabChange, children: React.createElement("div", { ref: wrapperRef },
            React.createElement(FilterWithSearcher, { searcherPlaceholder: "\uACE0\uAC1D\uBA85, \uC5F0\uB77D\uCC98, \uACE0\uAC1D\uBC88\uD638 \uAC80\uC0C9", extra: React.createElement(Layout.Inline, null,
                    React.createElement(FilterSaver, { store: store, isAll: isAll, prevFilter: getFilterInTab(activeTab), currentFilter: filter }),
                    React.createElement(StyleTableSetterDefaultTrigger, { onClick: function () { return setSetterVisible(true); } })), filter: [
                    {
                        key: 'condition',
                        type: 'radio',
                        items: [
                            { label: '교집합 검색', value: 'intersection', guide: '입력한 값들에 모두 만족해야 검색됩니다.' },
                            { label: '합집합 검색', value: 'union', guide: '입력한 값들 중 하나만 만족하면 검색됩니다.' }
                        ]
                    },
                    {
                        key: 'birthdayPreset',
                        type: 'checkbox',
                        title: '생일',
                        items: [
                            { label: '이번달', value: 'thisMonth' },
                            { label: '이번주', value: 'thisWeek' },
                            { label: '오늘', value: 'today' }
                        ]
                    },
                    {
                        key: 'birth',
                        type: 'dateRange',
                        title: '출생연도'
                    },
                    {
                        key: 'gender',
                        type: 'checkbox',
                        title: '성별',
                        items: [
                            { label: '여성', value: 'female' },
                            { label: '남성', value: 'male' }
                        ]
                    },
                    {
                        title: '고객번호',
                        key: 'customerNumber',
                        type: 'numberRange',
                        inputProps: { placeholder: '', suffix: '' }
                    },
                    { title: '주소', key: 'address', type: 'text' },
                    {
                        key: 'tag',
                        type: 'checkbox',
                        title: '태그',
                        items: tagOptions
                    },
                    { title: '메모', key: 'memo', type: 'text' },
                    {
                        key: 'manager',
                        type: 'checkbox',
                        title: '담당자',
                        items: workingManagers.map(function (item) { return ({
                            label: item.displayName,
                            value: item.id
                        }); })
                    },
                    {
                        key: 'group',
                        type: 'checkbox',
                        title: '등급',
                        items: groupOptions
                    },
                    {
                        key: 'hadPrepaid',
                        type: 'checkbox',
                        title: '선불액 구매내역',
                        items: [
                            { label: '있음', value: true },
                            { label: '없음', value: false }
                        ]
                    },
                    {
                        key: 'hadTicket',
                        type: 'checkbox',
                        title: '정기권 구매내역',
                        items: [
                            { label: '있음', value: true },
                            { label: '없음', value: false }
                        ]
                    },
                    {
                        key: 'availablePrepaid',
                        type: 'checkbox',
                        title: '사용가능 선불액',
                        items: [
                            { label: '있음', value: true },
                            { label: '없음', value: false }
                        ]
                    },
                    {
                        key: 'availableTickets',
                        type: 'checkbox',
                        title: '사용가능 정기권',
                        items: [
                            { label: '있음', value: true },
                            { label: '없음', value: false }
                        ]
                    },
                    {
                        title: '실 매출액',
                        key: 'totalPaid',
                        type: 'numberRange',
                        inputProps: { placeholder: '금액입력 ', suffix: '원' }
                    },
                    {
                        title: '잔여 선불액',
                        key: 'prepaid',
                        type: 'numberRange',
                        inputProps: { placeholder: '금액입력 ', suffix: '원' }
                    },
                    {
                        title: '공유 선불액',
                        key: 'sharedPrepaid',
                        type: 'numberRange',
                        inputProps: { placeholder: '금액입력 ', suffix: '원' }
                    },
                    {
                        title: '미수금',
                        key: 'unpaid',
                        type: 'numberRange',
                        inputProps: { placeholder: '금액입력 ', suffix: '원' }
                    },
                    {
                        title: '잔여 포인트',
                        key: 'customerPoint',
                        type: 'numberRange',
                        inputProps: { placeholder: '금액입력 ', suffix: '원' }
                    },
                    {
                        title: '방문횟수',
                        key: 'numberOfUsing',
                        type: 'numberRange',
                        inputProps: { placeholder: '횟수입력 ', suffix: '회' }
                    },
                    {
                        title: '노쇼 횟수',
                        key: 'numberOfNoshow',
                        type: 'numberRange',
                        inputProps: { placeholder: '횟수입력', suffix: '회' }
                    },
                    { title: '최근 방문일', key: 'lastVisitedAt', type: 'dateWithOperator' },
                    { title: '최초 등록일', key: 'createdAt', type: 'dateWithOperator' }
                ].concat((_g = (_f = props.store.customFields) === null || _f === void 0 ? void 0 : _f.customers) === null || _g === void 0 ? void 0 : _g.filter(function (item) { return !item.isDefault; }).map(function (item) {
                    var _a;
                    var field = {
                        title: item.label,
                        key: "data.custom.".concat(item.key),
                        type: '',
                        items: []
                    };
                    if (item.type === 'text' || item.type == 'textarea') {
                        field.type = 'text';
                    }
                    else if (item.type === 'date') {
                        field.type = 'dateWithOperator';
                    }
                    else if (item.type === 'select') {
                        field.type = 'checkbox';
                        field.items = (_a = item.options) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                            return {
                                value: item,
                                label: item
                            };
                        });
                    }
                    return field;
                })), value: (pagerOptions === null || pagerOptions === void 0 ? void 0 : pagerOptions.search)
                    ? __assign({ keyword: pagerOptions === null || pagerOptions === void 0 ? void 0 : pagerOptions.search }, convertCustomerFilterToFilterData(filter)) : convertCustomerFilterToFilterData(filter), onChange: function (value) {
                    var items = Object.keys(value)
                        .map(function (key) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
                        var customFieldType;
                        if ((key === null || key === void 0 ? void 0 : key.startsWith('data.custom')) && value && value[key]) {
                            var dataValue = value[key];
                            if (typeof dataValue === 'string') {
                                customFieldType = 'text';
                            }
                            else if (isArray(dataValue) && typeof dataValue[0] === 'string') {
                                customFieldType = 'select';
                            }
                            else if (dataValue === null || dataValue === void 0 ? void 0 : dataValue.operator) {
                                customFieldType = 'date';
                            }
                        }
                        if (([
                            'gender',
                            'manager',
                            'group',
                            'tag',
                            'hadPrepaid',
                            'hadTicket',
                            'birthdayPreset',
                            'availablePrepaid',
                            'availableTickets'
                        ].includes(key) ||
                            customFieldType === 'select') &&
                            value &&
                            value[key]) {
                            return value[key]
                                ? {
                                    key: key,
                                    type: 'enum',
                                    operator: '$in',
                                    values: value[key]
                                }
                                : null;
                        }
                        else if ((['name', 'memo', 'address', 'phone'].includes(key) || customFieldType === 'text') &&
                            value &&
                            value[key]) {
                            return value[key]
                                ? {
                                    key: key,
                                    type: 'string',
                                    operator: '$like',
                                    // string
                                    values: value[key]
                                }
                                : null;
                        }
                        else if ([
                            'customerNumber',
                            'totalPaid',
                            'numberOfNoshow',
                            'numberOfUsing',
                            'prepaid',
                            'unpaid',
                            'sharedPrepaid',
                            'customerPoint'
                        ].includes(key) &&
                            value &&
                            value[key]) {
                            var values = value[key];
                            if (!values) {
                                return null;
                            }
                            else {
                                if (isSafeInteger(values.start) && isSafeInteger(values.end)) {
                                    return {
                                        key: key,
                                        type: 'number',
                                        operator: '$between',
                                        // {start: date|number, end: date|number}
                                        values: value[key]
                                    };
                                }
                                else if (isSafeInteger(values.start)) {
                                    return {
                                        key: key,
                                        type: 'number',
                                        operator: '$gte',
                                        // {start: date|number, end: date|number}
                                        values: values.start
                                    };
                                }
                                else if (isSafeInteger(values.end)) {
                                    return {
                                        key: key,
                                        type: 'number',
                                        operator: '$lte',
                                        // {start: date|number, end: date|number}
                                        values: values.end
                                    };
                                }
                                else {
                                    return null;
                                }
                            }
                        }
                        else if ((['lastVisitedAt', 'createdAt'].includes(key) || customFieldType === 'date') &&
                            value &&
                            value[key]) {
                            var data = value[key];
                            if (data) {
                                var result = data.operator === '$between'
                                    ? {
                                        start: ((_b = (_a = data.value) === null || _a === void 0 ? void 0 : _a.start) === null || _b === void 0 ? void 0 : _b.toDate)
                                            ? (_d = (_c = data.value) === null || _c === void 0 ? void 0 : _c.start) === null || _d === void 0 ? void 0 : _d.toDate()
                                            : isNilOrEmptyArray((_e = data.value) === null || _e === void 0 ? void 0 : _e.start)
                                                ? null
                                                : (_f = data.value) === null || _f === void 0 ? void 0 : _f.start,
                                        end: ((_h = (_g = data.value) === null || _g === void 0 ? void 0 : _g.end) === null || _h === void 0 ? void 0 : _h.toDate)
                                            ? (_k = (_j = data.value) === null || _j === void 0 ? void 0 : _j.end) === null || _k === void 0 ? void 0 : _k.toDate()
                                            : isNilOrEmptyArray((_l = data.value) === null || _l === void 0 ? void 0 : _l.end)
                                                ? null
                                                : data.value.end
                                    }
                                    : ((_m = data.value) === null || _m === void 0 ? void 0 : _m.toDate)
                                        ? (_o = data.value) === null || _o === void 0 ? void 0 : _o.toDate()
                                        : isNilOrEmptyArray(data.value)
                                            ? null
                                            : data.value;
                                var values = data.operator === '$between'
                                    ? {
                                        start: ((_q = (_p = data.value) === null || _p === void 0 ? void 0 : _p.start) === null || _q === void 0 ? void 0 : _q.toDate)
                                            ? (_s = (_r = data.value) === null || _r === void 0 ? void 0 : _r.start) === null || _s === void 0 ? void 0 : _s.toDate()
                                            : isNilOrEmptyArray((_t = data.value) === null || _t === void 0 ? void 0 : _t.start)
                                                ? null
                                                : (_u = data.value) === null || _u === void 0 ? void 0 : _u.start,
                                        end: ((_w = (_v = data.value) === null || _v === void 0 ? void 0 : _v.end) === null || _w === void 0 ? void 0 : _w.toDate)
                                            ? (_y = (_x = data.value) === null || _x === void 0 ? void 0 : _x.end) === null || _y === void 0 ? void 0 : _y.toDate()
                                            : isNilOrEmptyArray((_z = data.value) === null || _z === void 0 ? void 0 : _z.end)
                                                ? null
                                                : data.value.end
                                    }
                                    : ((_0 = data.value) === null || _0 === void 0 ? void 0 : _0.toDate)
                                        ? (_1 = data.value) === null || _1 === void 0 ? void 0 : _1.toDate()
                                        : isNilOrEmptyArray(data.value)
                                            ? null
                                            : data.value;
                                return values
                                    ? {
                                        key: key,
                                        type: 'date',
                                        operator: data.operator,
                                        values: values
                                    }
                                    : null;
                            }
                            else {
                                return null;
                            }
                        }
                        else if (key === 'birthdayMMDD') {
                            var data = value[key];
                            return ((_2 = data === null || data === void 0 ? void 0 : data.start) === null || _2 === void 0 ? void 0 : _2.dd) || (data === null || data === void 0 ? void 0 : data.start.mm) || ((_3 = data === null || data === void 0 ? void 0 : data.end) === null || _3 === void 0 ? void 0 : _3.dd) || ((_4 = data === null || data === void 0 ? void 0 : data.end) === null || _4 === void 0 ? void 0 : _4.mm)
                                ? {
                                    key: key,
                                    type: 'MMDDRange',
                                    operator: '$between',
                                    values: {
                                        start: data === null || data === void 0 ? void 0 : data.start,
                                        end: data === null || data === void 0 ? void 0 : data.end
                                    }
                                }
                                : null;
                        }
                        else if (key === 'birth') {
                            var data = value[key];
                            return (data === null || data === void 0 ? void 0 : data.start) || (data === null || data === void 0 ? void 0 : data.end)
                                ? {
                                    key: key,
                                    type: 'date',
                                    operator: '$between',
                                    values: {
                                        start: data === null || data === void 0 ? void 0 : data.start,
                                        end: data === null || data === void 0 ? void 0 : data.end
                                    }
                                }
                                : null;
                        }
                        else {
                            return;
                        }
                    })
                        .filter(Boolean);
                    var isUnion = value.condition === 'union';
                    var newFilters;
                    if (isUnion) {
                        newFilters = __assign(__assign({}, filter), { condition: value.condition, data: {
                                and: [
                                    {
                                        or: items.filter(function (item) { return !isNilOrEmptyArray(item.values); })
                                    }
                                ]
                            } });
                    }
                    else {
                        newFilters = __assign(__assign({}, filter), { condition: value.condition, data: {
                                and: items
                                    .filter(function (item) { return !isNilOrEmptyArray(item.values); })
                                    .map(function (item) { return ({
                                    or: [item]
                                }); })
                            } });
                    }
                    setNewPagerOptions({
                        search: value === null || value === void 0 ? void 0 : value.keyword,
                        page: 1,
                        filter: newFilters
                    });
                    setFilter(newFilters);
                } }),
            React.createElement(SendButtonWrapper, { align: "middle", justify: "space-between" },
                React.createElement(CustomerCounter, { total: (pagerResult === null || pagerResult === void 0 ? void 0 : pagerResult.totalCount) || 0, selected: selectedCount }),
                React.createElement(Row, { justify: "end" },
                    React.createElement(SendMessageModal, { store: store, target: {
                            selectedCount: selectedCount,
                            customerIds: (customerTableSelectData === null || customerTableSelectData === void 0 ? void 0 : customerTableSelectData.customerIds) || [],
                            isAll: (customerTableSelectData === null || customerTableSelectData === void 0 ? void 0 : customerTableSelectData.isAll) || false,
                            filters: query.filter,
                            filterTab: activeTab,
                            keyword: pagerOptions.search
                        } }))),
            React.createElement(CustomerTable, { resizable: {
                    additionalColumnWidth: 60
                }, availableColumnSettings: true, columnSetterVisible: setterVisible, id: "tableInCustomerTab", onChange: handleTableSortAndPagerChange, selectData: customerTableSelectData, selectedCount: selectedCount, onSelectDataChange: handleSelectDataChange, onCloseSetter: function () { return setSetterVisible(false); }, scroll: scroll, style: { cursor: 'pointer' }, pagination: getPaginateObject(), onRow: function (record) {
                    return {
                        onClick: function (event) { return props.onRowClick && props.onRowClick(record); }
                    };
                }, columns: CustomerTableColumns(true).concat((_j = (_h = store === null || store === void 0 ? void 0 : store.customFields) === null || _h === void 0 ? void 0 : _h.customers) === null || _j === void 0 ? void 0 : _j.filter(function (item) { return !item.isDefault; }).map(function (item) {
                    var key = "data.custom.".concat(item.key);
                    return {
                        title: item.label,
                        key: key,
                        dataIndex: key === null || key === void 0 ? void 0 : key.split('.'),
                        width: 150,
                        render: function (_, row) {
                            return isValidDateString(_) ? dayjs(_).format(DATE_FORMAT) : _;
                        }
                    };
                })), dataSource: (_k = pagerResult === null || pagerResult === void 0 ? void 0 : pagerResult.items) === null || _k === void 0 ? void 0 : _k.map(function (item) {
                    return new CustomerModel(__assign(__assign({}, item), { key: item.id }));
                }), loading: isLoading })) }));
};
var StyledCardWithTabs = styled(CardWithTabs)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) { return createCSS(props.tabList); });
var createCSS = function (tabList) {
    return tabList
        .map(function (tab) {
        if (tab.key === 'all') {
            return "\n        div[data-node-key=\"".concat(tab.key, "\"] {\n        margin-top: 5px !important;\n        & > div {\n          color: ").concat(COLORS.black, " !important;\n          ").concat(typographyByName('body1'), "\n          font-size: 14px !important;\n          font-weight: 500 !important;\n          letter-spacing: -0.8px;\n        }\n      }\n        ");
        }
        else {
            return "\n        div[data-node-key=\"".concat(tab.key, "\"] {\n          background-color: ").concat(tab.color || COLORS.tag7, " !important;\n          height: 24px;\n          margin: 0 0 0 20px !important;\n          margin-top: 10px !important;\n          border-radius: 2px;\n          padding: 0 4px !important;\n          & > div {\n            color: ").concat(COLORS.black, " !important;\n            ").concat(typographyByName('body1'), "\n          font-size: 14px !important;\n          font-weight: 500 !important;\n          letter-spacing: -0.8px;\n        }\n      }\n      ");
        }
    })
        .join(';');
};
var StyleTableSetterDefaultTrigger = styled(TableSetterDefaultTrigger)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  // position: absolute;\n  // left: 320px;\n"], ["\n  // position: absolute;\n  // left: 320px;\n"])));
export var CustomerTable = function (props) {
    var onSelectDataChange = props.onSelectDataChange, _a = props.selectData, selectData = _a === void 0 ? { isAll: false, customerIds: [] } : _a, rest = __rest(props, ["onSelectDataChange", "selectData"]);
    var handleChange = function (type) {
        var newSelectData = {};
        if (type === 'all') {
            newSelectData = {
                isAll: true,
                customerIds: []
            };
        }
        else if (type === 'allThisPage') {
            newSelectData = {
                isAll: false,
                customerIds: props.dataSource.filter(function (item) { return item.availableReceive; }).map(function (item) { return item.id; })
            };
        }
        else if (type === 'clear') {
            newSelectData = {
                isAll: false,
                customerIds: []
            };
        }
        onSelectDataChange(newSelectData);
    };
    var handleSelect = function (customerId) {
        var newIds = uniq(addOrRemove((selectData === null || selectData === void 0 ? void 0 : selectData.customerIds) || [], customerId));
        var newSelectData = { isAll: selectData.isAll, customerIds: newIds };
        onSelectDataChange(newSelectData);
    };
    var items = [
        {
            name: 'all',
            label: '전체선택',
            onClick: function () {
                handleChange('all');
            }
        },
        {
            name: 'allThisPage',
            label: '현재 페이지만 선택',
            onClick: function () {
                handleChange('allThisPage');
            }
        },
        {
            name: 'clear',
            label: '전체선택해제',
            onClick: function () {
                handleChange('clear');
            }
        }
    ];
    // const [selectData, setSelectData] = React.useState<CustomerTableSelectData>({ isAll: false, customerIds: [] });
    // React.useEffect(() => {
    //   if (!isEqual(props.selectData, selectData) && props.selectData) {
    //     setSelectData(props.selectData);
    //   }
    // }, [props.selectData]);
    var isChecked = function (id, disabled) {
        var isAll = selectData.isAll;
        var customerIds = selectData.customerIds || [];
        if (disabled) {
            return false;
        }
        if (isAll) {
            return !customerIds.includes(id);
        }
        else {
            return customerIds.includes(id);
        }
    };
    return (React.createElement(TableComponent, __assign({}, rest, { rowSelection: {
            columnWidth: 40,
            columnTitle: (React.createElement(CustomDropdown, { items: items, children: React.createElement(Checkbox, { checked: selectData.isAll && isEmpty(selectData.customerIds) && props.selectedCount > 0, indeterminate: !isEmpty(selectData.customerIds) && props.selectedCount > 0 }) })),
            renderCell: function (value, raw) {
                var disabled = !raw.availableReceive;
                return (React.createElement(Checkbox, { disabled: disabled, onClick: function (ev) {
                        ev.stopPropagation();
                        ev.preventDefault();
                        if (!disabled) {
                            handleSelect(raw.id);
                        }
                    }, onChange: function (ev) { }, checked: isChecked(raw.id, disabled) }));
            }
        } })));
};
var FilterSaver = function (props) {
    var _a, _b;
    var isAll = props.isAll, prevFilter = props.prevFilter, currentFilter = props.currentFilter, store = props.store;
    var updateStore = StoreQuery.useUpdateStore();
    var _c = React.useState(), settingOpen = _c[0], setSettingOpen = _c[1];
    var _d = React.useState(), filterData = _d[0], setFilterData = _d[1];
    var handleClickExtra = function (type) { return __awaiter(void 0, void 0, void 0, function () {
        var isValidate, prevFilters, newFilters, err_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (type === 'settings') {
                        return [2 /*return*/, setSettingOpen(true)];
                    }
                    isValidate = validateFilter(((_a = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.data) === null || _a === void 0 ? void 0 : _a.and) || []);
                    if (!isValidate) {
                        return [2 /*return*/];
                    }
                    if (!(type === 'save')) return [3 /*break*/, 5];
                    if (!isAll) return [3 /*break*/, 1];
                    setFilterData(currentFilter);
                    return [3 /*break*/, 5];
                case 1:
                    prevFilters = ((_b = store.preference) === null || _b === void 0 ? void 0 : _b.customerFilters) || [];
                    _c.label = 2;
                case 2:
                    _c.trys.push([2, 4, , 5]);
                    newFilters = prevFilters.map(function (item) {
                        var prevId = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.id;
                        if (prevId) {
                            if (item.id === currentFilter.id) {
                                return __assign(__assign({}, item), currentFilter);
                            }
                            else {
                                return item;
                            }
                        }
                        else {
                            return item;
                        }
                    });
                    return [4 /*yield*/, updateStore.mutateAsync({
                            id: store.id,
                            preference: __assign(__assign({}, store.preference), { customerFilters: newFilters })
                        })];
                case 3:
                    _c.sent();
                    notification.success({ message: '세그먼트 조건이 변경되었습니다.' });
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _c.sent();
                    notification.error({ message: '세그먼트 설정 중 에러발생' });
                    return [3 /*break*/, 5];
                case 5:
                    // 세그먼트 삭제
                    if (type === 'delete') {
                        Modal.confirm({
                            title: '정말 삭제하시겠습니까?',
                            centered: true,
                            content: '삭제된 세그먼트는 다시 복구하실 수 없습니다.',
                            onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var prevPreference, id, newFilters;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            prevPreference = store.preference;
                                            id = currentFilter === null || currentFilter === void 0 ? void 0 : currentFilter.id;
                                            if (!id) return [3 /*break*/, 2];
                                            newFilters = (((_a = store.preference) === null || _a === void 0 ? void 0 : _a.customerFilters) || []).filter(function (item) {
                                                return item.id !== id;
                                            });
                                            return [4 /*yield*/, updateStore.mutateAsync({
                                                    id: store.id,
                                                    preference: __assign(__assign({}, prevPreference), { customerFilters: newFilters })
                                                })];
                                        case 1:
                                            _b.sent();
                                            notification.success({ message: '세그먼트 삭제가 완료되었습니다.' });
                                            _b.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var segments = ((_b = (_a = props.store) === null || _a === void 0 ? void 0 : _a.preference) === null || _b === void 0 ? void 0 : _b.customerFilters) || [];
    var handleSubmit = function (segments) { return __awaiter(void 0, void 0, void 0, function () {
        var prevPreference;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    prevPreference = store.preference;
                    return [4 /*yield*/, updateStore.mutateAsync({
                            id: store.id,
                            preference: __assign(__assign({}, prevPreference), { customerFilters: segments })
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomDropdown, { items: [
                {
                    name: 'save',
                    label: isAll ? '새 세그먼트 만들기' : '현재 세그먼트 조건 저장',
                    icon: 'save',
                    onClick: function () {
                        handleClickExtra('save');
                    },
                    disabled: isAll ? !currentFilter : isEqual(prevFilter, currentFilter)
                },
                {
                    name: 'settings',
                    label: '전체 세그먼트 설정',
                    icon: 'settings',
                    onClick: function () {
                        handleClickExtra('settings');
                    }
                },
                !isAll && {
                    name: 'delete',
                    label: '현재 세그먼트 삭제',
                    icon: 'trash',
                    onClick: function () {
                        handleClickExtra('delete');
                    },
                    disabled: isAll,
                    color: COLORS.calendarRedActive
                }
            ].filter(Boolean) },
            React.createElement(Button, { size: "large", style: { marginLeft: '10px', paddingTop: '8px' } },
                React.createElement(Icon, { name: "more-horizontal" }))),
        React.createElement(SegmentUpsertDrawer, { values: filterData, onClose: function () { return setFilterData(null); }, segments: segments, onSubmit: handleSubmit }),
        React.createElement(SegmentSettingDrawer, { open: settingOpen, onClose: function () { return setSettingOpen(null); }, segments: segments, onSubmit: handleSubmit })));
};
var SegmentSettingDrawer = function (props) {
    var title = '전체 세그먼트 설정';
    var form = Form.useForm()[0];
    var _a = React.useState(), filterData = _a[0], setFilterData = _a[1];
    var handleOrder = function (records) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.onSubmit(records.map(function (item) { return item; }))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(DrawerComponent, { title: title, open: props.open, onClose: props.onClose, footer: React.createElement(DrawerFooter, { onCancel: props.onClose, onConfirm: function () { return form.submit(); }, confirmText: "\uC800\uC7A5" }) },
        React.createElement(SegmentUpsertDrawer, { values: filterData, onClose: function () { return setFilterData(null); }, segments: props.segments, onSubmit: props.onSubmit }),
        React.createElement(SortableTables, { tables: ['customerSegments'].map(function (type, index) {
                return {
                    bordered: false,
                    onRow: function (record) { return ({
                        onClick: function () {
                            setFilterData(record);
                        }
                    }); },
                    id: index,
                    columns: [
                        {
                            title: '세그먼트명',
                            dataIndex: 'name',
                            key: 'name',
                            render: function (value, record, index) {
                                return value;
                            }
                        },
                        {
                            title: '대표색상',
                            dataIndex: 'color',
                            key: 'color',
                            // className
                            fixed: true,
                            render: function (_, record, index) {
                                return React.createElement(ColorBox, { "$color": record.color || COLORS.segment1 });
                            }
                        }
                    ],
                    onSortEnd: handleOrder,
                    dataSource: (props.segments || []).map(function (item, index) { return (__assign(__assign({}, item), { key: index, index: index })); })
                };
            }) })));
};
var ColorBox = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 60px;\n  height: 20px;\n  border-radius: 2px;\n  background-color: ", ";\n"], ["\n  width: 60px;\n  height: 20px;\n  border-radius: 2px;\n  background-color: ", ";\n"])), function (props) { return props.$color; });
var SegmentUpsertDrawer = function (props) {
    var _a, _b, _c;
    var type = !((_a = props.values) === null || _a === void 0 ? void 0 : _a.id) ? 'create' : 'update';
    var isCreate = type === 'create';
    var open = !!props.values;
    var title = type === 'create' ? '새 세그먼트 만들기' : '세그먼트 수정';
    var form = Form.useForm()[0];
    React.useEffect(function () {
        var _a, _b;
        form.setFieldsValue({
            name: ((_a = props.values) === null || _a === void 0 ? void 0 : _a.name) || '',
            color: ((_b = props.values) === null || _b === void 0 ? void 0 : _b.color) || COLORS.segment1
        });
    }, [props.values]);
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var newSegments, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isCreate) {
                        newSegments = props.segments.concat(__assign(__assign({}, props.values), { id: createUUID(), name: values.name, color: values.color }));
                    }
                    else {
                        newSegments = props.segments.map(function (item) {
                            var _a;
                            if (item.id === ((_a = props.values) === null || _a === void 0 ? void 0 : _a.id)) {
                                return __assign(__assign({}, item), values);
                            }
                            else {
                                return item;
                            }
                        });
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, props.onSubmit(newSegments)];
                case 2:
                    _a.sent();
                    notification.success({ message: "\uC138\uADF8\uBA3C\uD2B8 ".concat(isCreate ? '생성' : '수정', "\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.") });
                    props.onClose();
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    notification.success({ message: "\uC138\uADF8\uBA3C\uD2B8 ".concat(isCreate ? '생성' : '수정', "\uC911 \uC5D0\uB7EC \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4.") });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(DrawerComponent, { title: title, open: open, onClose: props.onClose, footer: React.createElement(DrawerFooter, { onCancel: props.onClose, onConfirm: function () { return form.submit(); }, confirmText: "\uC800\uC7A5" }) },
        React.createElement(AntdForm, { formProps: {
                form: form,
                initialValues: {
                    name: (_b = props.values) === null || _b === void 0 ? void 0 : _b.name,
                    color: ((_c = props.values) === null || _c === void 0 ? void 0 : _c.color) || COLORS.segment1
                },
                onFinish: handleSubmit
            }, onSubmit: handleSubmit, fields: [
                {
                    type: 'text',
                    itemProps: {
                        name: 'name',
                        label: '세그먼트명',
                        rules: [{ type: 'string', required: true, min: 1, max: 10 }]
                    },
                    inputProps: { placeholder: '세그먼트명을 입력해주세요.', autoFocus: true }
                },
                {
                    type: 'color',
                    itemProps: {
                        name: 'color',
                        label: '대표색상',
                        rules: [{ required: true }]
                    },
                    inputProps: {
                        placeholder: '색상 선택',
                        options: [
                            COLORS.segment1,
                            COLORS.segment2,
                            COLORS.segment3,
                            COLORS.segment4,
                            COLORS.segment5,
                            COLORS.segment6,
                            COLORS.segment7,
                            COLORS.segment8
                        ].map(function (color) { return ({
                            label: '',
                            value: color
                        }); })
                    }
                }
            ] })));
};
var CustomerCounter = function (props) {
    var Total = function () { return (React.createElement(Row, { align: "middle" },
        React.createElement(TextComponent, { type: "headline3", children: "\uCD1D", color: props.selected ? COLORS.gray4 : COLORS.gray1, marginRight: 5 }),
        React.createElement(TextComponent, { type: "headline3", children: "".concat(props.total, "\uBA85"), color: props.selected ? COLORS.gray4 : COLORS.gray1 }))); };
    var Selected = function () { return (React.createElement(Row, { align: "middle" },
        React.createElement(TextComponent, { type: "headline3", children: "\uC120\uD0DD", color: COLORS.gray1, marginRight: 5 }),
        React.createElement(TextComponent, { type: "headline3", children: "".concat(props.selected, "\uBA85"), color: COLORS.gray1 }))); };
    return (React.createElement(Row, { align: "middle", justify: "end", wrap: false, className: props.className },
        !props.selected && React.createElement(Total, null),
        props.selected ? React.createElement(Selected, null) : React.createElement("div", null),
        props.selected ? React.createElement(StyledDivider, { type: "vertical", marginRight: 8, marginLeft: 8 }) : React.createElement("div", null),
        props.selected ? React.createElement(Total, null) : React.createElement("div", null)));
};
export var AbsoluteCounter = styled(CustomerCounter)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 20px;\n  right: 250px;\n  ", "\n"], ["\n  position: absolute;\n  bottom: 20px;\n  right: 250px;\n  ", "\n"])), MediaQuery.tabletWithMobile(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: unset;\n  bottom: 60px;\n  right: 16px;\n  "], ["\n  position: unset;\n  bottom: 60px;\n  right: 16px;\n  "]))));
var Wrapper = styled(Row)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 36px 26px;\n"], ["\n  padding: 36px 26px;\n"])));
var SendButtonWrapper = styled(Row)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  margin-bottom: 12px;\n"], ["\n  width: 100%;\n  margin-bottom: 12px;\n"])));
export var validateFilter = function (filters) {
    var isValidate = Lodash.every(filters.map(function (filter) {
        return Lodash.every(filter.or.map(function (orItem) {
            return orItem.key &&
                orItem.operator &&
                orItem.type &&
                (orItem.values || orItem.values === false || orItem.values === 0);
        }));
    }));
    if (Lodash.isEmpty(filters)) {
        notification.error({ message: '세그먼트 조건을 추가 후 저장해주세요.' });
        return false;
    }
    if (!isValidate) {
        notification.error({ message: '세그먼트 조건이 유효하지 않습니다. 조건을 확인해주세요.' });
        return false;
    }
    return true;
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
